.profileImgDiv {
    border: 1px solid rgba(108, 118, 131, 1);
    border-radius: 50%;
    width: 80px;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    cursor: pointer;
    margin: 5%;
}

.avatarBox {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    overflow: auto;
    overscroll-behavior: contain;
    max-height: 20rem;
}