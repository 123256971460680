.authFooter {
    gap: 32px;
    border: 1px;
    bottom: 0;
    display: flex;
    justify-content: space-between;
    position: relative;
    height: 5.5rem;
    align-items: center;
    width: 100%;
    padding: 0 4rem;
    margin-top: 7rem;
}

@media screen and (min-height: 800px) {
    .authFooter {
        position: absolute !important;
    }
}

.footer {
    background: #1F2937;
    border-top: 1px solid #374151;
    padding: 20px 75px;
    transition: margin-left 0.3s ease-in-out;
}

/* added margin for sidemenu expansion*/
.footer.sidebar-open {
    margin-left: 10rem;
}

@media screen and (max-width: 431px) {
    .authFooter {
        display: none;
    }
}

@media screen and ((min-width: 426px) and (max-width: 769px)) {
    .authFooter {
        padding: 0 2rem;
    }

    .footer {
        padding: 20px;
    }
}

.navs {
    display: flex;
    gap: 24px;
    font-family: var(--fontFamilyNunito);
    font-size: 1rem;
}

.navlink{
    text-decoration: none;
    color: inherit;
}

@media screen and (max-width: 500px) {
    .section2 {
        flex-direction: column !important;
    }

    .navs {
        flex-wrap: wrap;
    }
}

.currency {
    width: 99px;
    height: 28px;
    padding: 4px 8px 4px 8px;
    gap: 4px;
    border-radius: 4px;
    background: linear-gradient(180deg, rgba(217, 229, 255, 0.15) 0%, rgba(212, 225, 255, 0.0465) 100%);
    border: 1px solid #6B7280;
    color: #D1D5DB;
    display: flex;
    align-items: center;
}

.language {
    width: 136px;
    height: 28px;
    padding: 4px 8px 4px 8px;
    border-radius: 4px;
    background: linear-gradient(180deg, rgba(217, 229, 255, 0.15) 0%, rgba(212, 225, 255, 0.0465) 100%);
    border: 1px solid #6B7280;
    color: #D1D5DB;
    gap: 4px;
    display: flex;
    align-items: center;
}

@media screen and ((max-width: 767px)) {
    .authFooter {
        display: none;
    }
}

@media screen and (max-width: 851px) {
    .authFooter {
        padding: 0 1rem;
    }
}

@media screen and (max-width: 541px) {
    .footer {
        padding: 10px;
    }

}

.authFooterC {
    color: #9CA3AF;
    font-family: var(--fontFamilyNunito);
    font-weight: 700;
    line-height: 24px;
    text-align: left;
    font-size: clamp(10px, 2vh, 14px);
}

.authFooterMoonCake {
    height: clamp(10px, 3vh, 21.19px);
}

.authFooterMedia {
    height: clamp(10px, 2.5vh, 16px);
}

.div2 {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
}

.div3 {
    display: flex;
    flex-direction: column;
    gap: 32px;
}

.div4 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 8px;
}

.span1 {
    display: flex;
    justify-content: flex-end;
    font-size: 0.875em;
    font-family: var(--fontFamilyNunito);
    font-weight: 800;
    color: #6B7280;
    text-transform: uppercase;
}

.img1 {
    width: 8rem;
    height: 2rem;
    object-fit: contain;
}

.div5 {
    flex-direction: row;
}

.div6 {
    display: flex;
    align-items: center;
    gap: 16px;
}

.option {
    font-family: var(--fontFamilyFredokaOne);
    font-weight: 400;
    font-size: 1em;
    color: #D9D9D9;
}

.div7 {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    border-top: 1px solid hsl(210deg 20% 98% / 10%);
    color: #9CA3AF;
    font-family: var(--fontFamilyNunito);
    font-size: 0.875rem;
    font-weight: 700;
}

.div8 {
    display: flex;
    align-items: center;
    gap: 0.75rem;
    justify-content: space-between;
}

.span2 {
    height: 5px;
    width: 5px;
    background-color: #bbb;
    border-radius: 50%;
    display: inline-block;
}

.div9 {
    display: flex;
    align-items: center;
    gap: 16px;
}

.div10 {
    display: flex;
    align-items: center;
    gap: 4px;
}

@media screen and (max-width: 522px) {
    .div2 {
        flex-direction: column;
        gap: 16px;
    }

    .span1 {
        justify-content: flex-start;
    }

    .div5 {
        flex-direction: column;
    }

    .div7 {
        flex-direction: column;
    }

    .div8 {
        justify-content: space-between;
    }
}