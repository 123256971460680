.referral {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 720px;
    width: 100%;
    gap: 32px;
    margin: auto;
}

.refPending {
    width: 73px;
    height: 26px;
    padding: 2px 6px 2px 6px;
    gap: 2px;
    border-radius: 6px;
    background: var(--color-orange-900, #852100);
    font-family: var(--fontFamilyNunito);
    font-size: 14px;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: -0.03em;
    text-align: center;
    text-transform: uppercase;
    float: right;
}

.refCompleted {
    width: 94px;
    height: 26px;
    padding: 2px 6px 2px 6px;
    gap: 2px;
    border-radius: 6px;
    background: #224A38;
    font-family: var(--fontFamilyNunito);
    font-size: 14px;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: -0.03em;
    text-align: center;
    text-transform: uppercase;
    float: right;
}

.refPillText {
    align-items: center;
}

.refTableDiv {
    margin: auto;
}

@media screen and (max-width: 992px) {
    .refTableDiv {
        overflow: scroll;
        overscroll-behavior: contain;
        margin: auto;
        width: 100%;
    }

}