.transfer-modal
  display: flex
  justify-content: center
  text-align: center

  // .cursor
  //   padding: 50px 16px

  .title, svg
    color: var(--highlightFontColor)

  .title
    font-size: 18px
    font-weight: bold
    margin-bottom: 16px

  .transfer-deposit
    width: 100%
    border-right: 1px solid rgba(#fff,0.1)
    // height: 40vh
    display: flex
    align-items: center
    justify-content: center
    flex-direction: column

  .transfer-withdraw
    width: 100%
    // height: 40vh
    display: flex
    align-items: center
    justify-content: center
    flex-direction: column

  svg
    margin-bottom: 15px

  span
    color: #fff

  .est
    font-size: 14px
    font-style: italic
    opacity: 0.7
    margin-top: 10px
