.divtitle {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 6px;
}

.divbody {
    font-size: 16px;
    font-weight: 500;
    line-height: 26px;
    font-family: var(--fontFamilyNunito);
}