.inputLabel {
    font-family: var(--fontFamilyNunito);
    /* font-size: 16px; */
    font-size: clamp(12px, 1.5vw, 16px);
    font-weight: 700;
    line-height: 24px;
    text-align: left;
    opacity: 70%;
}

.inputSelect {
    background: #1F2937;
    border: 1px solid #374151;
    width: 242.5px;
    height: 44px;
    padding: 10px 16px 10px 16px;
    gap: 8px;
    border-radius: 12px;
    color: #fff;
    cursor: pointer;
}

.input {
    width: 497px;
    height: 44px;
    padding: 10px 40px 10px 16px;
    gap: 8px;
    border-radius: 12px;
    background: #1F2937;
    border: 1px solid #374151;
    font-family: var(--fontFamilyNunito);
    /* font-size: 16px; */
    font-size: clamp(12px, 1.5vw, 16px);
    font-weight: 700;
    line-height: 24px;
    text-align: left;
    color: #FFFFFF;
}

.inputWithdrawAdd {
    padding-right: 16px;
}

.inputWithdrawAmount {
    padding-right: 80px;

}

.inputWithdraw {
    background: #030712 !important;
}

.lineBreak {
    border: 1px solid #FFFFFF;
    width: 224.5px;
    opacity: 20%;
}

.endText {
    font-family: var(--fontFamilyNunito);
    /* font-size: 16px; */
    font-size: clamp(12px, 1.5vw, 16px);
    font-weight: 700;
    line-height: 24px;
    text-align: center;
    color: #fff
}

.endTextWithdraw {
    width: 497px;
    height: 60px;
    top: 416px;
    left: 24px;
    gap: 0px;
    opacity: 70%;
    font-family: var(--fontFamilyNunito);
    /* font-size: 14px; */
    font-size: clamp(10px, 3vw, 14px);
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.02em;
    text-align: left;
    color: #FFFFFF;
}

.max {
    width: 60px;
    padding: 8px 12px 8px 12px;
    gap: 8px;
    background: linear-gradient(180deg, #FBAE56 0%, #F28100 100%);
    border: 1px solid #49586A;
    cursor: pointer;
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
    height: 44px;
    font-family: var(--fontFamilyFredoka);
    /* font-size: 16px; */
    font-size: clamp(12px, 1.5vw, 16px);
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.02em;
    text-align: center;
    box-shadow: 0px 1px 0px 0px #00000026;
}

.historyth {
    width: 62px;
    height: 24px;
    left: 8px;
    font-family: var(--fontFamilyFredoka);
    /* font-size: 14px; */
    font-size: clamp(10px, 4vw, 14px);
    font-weight: 500;
    line-height: 24px;
    text-align: left;

}

.divider {
    width: 497px;
    height: 0px;
    gap: 0px;
    border: 1px 0px 0px 0px;
    opacity: 21%;
    border-top: 1px solid #CBBCE5
}

.qrCode {
    width: 146px;
    height: 144px;
}

.listItem {
    background: none;
    color: #fff;
    border: none;
    font-family: var(--fontFamilyNunito);
    /* font-size: "1em"; */
    font-size: clamp(12px, 1.5vw, 16px);
    font-weight: 700;
    line-height: 24px;
    height: 44px;
    cursor: pointer;
    padding: 0.5rem 1rem;
}

.dropdownMenu {
    background: #1F2937;
    min-width: 242.5px;
    font-size: clamp(12px, 1.5vw, 16px);
}


@media screen and (max-width: 576px) {
    .wrapBody {
        width: 100% !important;
        border: none !important;
    }

    .inputSelect {
        width: 41.19vw !important;
        height: auto;
        overflow-x: scroll !important;
    }

    .inputSelectWallet {
        width: 100% !important;
        overflow-x: auto !important;
    }

    .wrapInput {
        justify-content: flex-start !important;
    }

    .endTextWithdraw {
        width: 100% !important;
        /* font-size: 0.9em; */
    }

    .input {
        width: 100% !important;
    }

    .dropdownMenu {
        width: 100% !important;
    }
}


.dollarValue {
    font-family: var(--fontFamilyNunito);
    font-size: clamp(10px, 2vh, 16px);
    font-weight: 600;
    line-height: 24px;
    color: #9CA3AF;
}

.copyIcon {
    position: absolute;
    right: 7%;
    cursor: pointer;
}

.wrapBody2 {
    width: inherit;
    background: #111827;
}

.depositDiv1 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 20px
}

.depositDi2 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 12px;
    left: 24px;
    top: 168px;
    height: 68px;
    width: 497px;
}

.depoRela {
    position: relative;
}

.depoDiv2 {
    display: flex;
    align-items: center;
    gap: 16px;
}

.optionsDiv {
    display: flex;
    align-items: center;
    gap: 4px;
}

.dropDownDiv1 {
    color: #fff;
    gap: 4px;
    font-family: var(--fontFamilyNunito);
    font-weight: 700;
    display: flex;
    align-items: center;
    font-size: clamp(12px, 2vw, 16px);
}

.dummyListDiv1 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: linear-gradient(180deg, #2C3847 0%, #222C38 100%);
    border-radius: 12px;
    padding: 12px;
    height: 48px;
}

.dummyListP1 {
    border-collapse: separate;
    border-radius: 10px 0px 0px 10px;
    font-weight: 700;
    font-family: var(--fontFamilyNunito);
}

.dummyListP2 {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
    font-family: var(--fontFamilyNunito);
    font-weight: 500;
}

.dummyListP3 {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 4px;
    font-family: var(--fontFamilyNunito);
    font-weight: 500;
}

.HistoryDiv1 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 20px
}

.HistoryDiv2 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 497px;
    height: 68px;
    top: 168px;
    left: 24px;
    gap: 12px;
}

.HistoryIFSDiv1 {
    gap: 4px;
    padding: 12px;
    display: flex;
    flex-direction: column;
}

.WalletTitleDiv1 {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 6px;
}

/* Withdraw Css */
.withDiv1 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 20px
}

.withSpan1 {
    color: red;
}

.withInput1 {
    position: relative;
}

.withDiv01 {
    display: flex;
    align-items: center;
    gap: 8px;
}

.withDiv2 {
    position: absolute;
    display: flex;
    right: 21px;
    align-items: center;
    gap: 8px;
}