@keyframes rotateBackground {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.raysBanner {
    position: relative;
    overflow: hidden;
    box-shadow: 0px 3px 2.5px 0px #5D6976 inset;
    background: linear-gradient(180deg, #414D5C 0%, #1B2025 100%);
    max-width: 100%;
    height: 321px;
    gap: 0px;
    opacity: 1px;
    margin: 2px;
    border-radius: 24px;
}

.raysBanner::before {
    content: "";
    position: absolute;
    top: -135%;
    left: -4%;
    width: 166%;
    height: 380%;
    background: repeating-conic-gradient(from 53deg at 50% 50%,
            rgba(120, 76, 76, 0) -1deg 10deg,
            rgba(175, 167, 148, 0) 10deg 20deg,
            rgba(255, 255, 255, 0.03) 20deg 35deg);
    transform-origin: 50% 50%;
    animation: rotateBackground 20s linear infinite;
    z-index: 1;
}

.raysBanner>* {
    position: relative;
    z-index: 2;
}

.heading {
    font-family: var(--fontFamilyFredokaOne);
    font-size: clamp(1.6em, 2.5vw, 2em);
    font-weight: 400;
    letter-spacing: 0.02em;
    text-align: left;
    text-transform: uppercase;
}

.Checkout {
    color: #fff;
    font-family: var(--fontFamilyNunito);
    line-height: 1.2em;
    font-size: 1em;
    font-weight: 500;
    text-align: left;
}

.stand {
    display: flex;
    justify-content: left;
    align-items: center;
}

.dth img {
    width: 387px;
    height: 320px;
    border-radius: 17.36px;
}

.leftsection {
    display: flex;
    padding: 0px 20px;
    justify-content: left;
}

.leftsection img {
    width: 28.33px;
    height: 28.33px;
}

.headingGradient {
    background-image: linear-gradient(to right, #FFE30B 0%, #FFA627 100%);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
}

.headingGradient2 {
    background-image: linear-gradient(to right, #FFD809 0%, #FF8A00 100%);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
}

.btn:hover {
    color: #ffffffa4;
}

.dthImg {
    width: 14.188rem;
    height: 13.313rem;
}

@media screen and (max-width: 992px) {
    .bannerImage {
        display: none !important;
    }
}

@media screen and (max-width: 1200px) {
    .dthImg {
        width: 12.188rem;
        height: 12.313rem;
    }

    .bannerImage {
        width: 33.33333333% !important;
    }
}

.div1{
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

.div2 {
    display: flex;
    gap: 20px;
    flex-direction: column;
}

.div3 {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.img1 {
    position: relative;
    width: 1.5rem;
    height: 1.5rem;
}