.dice {
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 7.5% 4.5%;
}


.diceContainer {
    position: absolute;
    top: 70%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.mainCard {
    /* padding: 32px 24px 32px 24px; */
    background: #122D4B;
    border: 2px solid #68748F;
    max-height: 701px;
    max-width: fit-content;
    height: 80%;
    width: 100%;
    aspect-ratio: 508/701;
    /* gap: 3%; */
}


.numberLogo {
    /* width: 18.75rem; */
    /* max-height: 5rem; */
    height: 14%;
    position: absolute;
    top: -8%;
    display: flex;
    align-self: center;
}

.ProgressBarmeter {
    display: flex;
    color: #bbb;
    justify-content: space-between;
}

/* Progress bar */
.progressContainer {
    padding: 0 3%;
}

.progress {
    max-width: 412px;
    height: 8px;
    width: 100%;
}

.progress {
    margin: 0 5px;
    box-shadow: inset 0 0 4px #000000;
    /* --bs-progress-height: 0.6rem; */
    --bs-progress-height: 31%;
}

.progressUnder {
    --bs-progress-bar-bg: #61d05c;
    --bs-progress-bg: #eb5128;
}

.progressOver {
    --bs-progress-bar-bg: #eb5128 !important;
    --bs-progress-bg: #61d05c !important;
}

.group2 {
    display: flex;
    gap: 22px;
    flex-direction: column;
}

@media screen and (max-height: 840px) {
    .group2 {
        gap: calc(22px - 10px);
    }
}

@media screen and (max-height: 793px) {
    .dice {
        gap: 3%
    }

    .mainCard {
        height: 80%;
    }
}

@media screen and (max-height: 768px) {
    .dice {
        gap: 5%
    }

    .mainCard {
        height: 80%;
    }
}

@media screen and (max-height: 768px) {
    .mainCard {
        /* aspect-ratio: 390/669 !important; */
        height: 80%;
        /* padding: 20px 12px; */
    }

    /* .dice {
        gap: 3%
    } */

    /* .group2 {
        gap: 2%
    } */

    .card-text {
        height: 8.3% !important;
    }

    .inputSliderDiv {
        height: 40% !important;
        justify-content: center !important;
    }

    .group4 {
        height: 25% !important;
        padding: 2% !important;
        gap: 6px !important;
        justify-content: center;
    }
}

@media screen and (max-height: 740px) {
    .dice {
        gap: 4%
    }

    .mainCard {
        height: 80%;
    }
}

@media screen and (max-height: 720px) {
    .dice {
        gap: calc(24px - 8px);
    }

    .group2 {
        gap: calc(22px - 11px);
    }
}

@media screen and (max-height: 729px) {
    .dice {
        gap: 7%;
    }

    .group2 {
        gap: 3%;
    }
}

@media screen and (max-height: 699px) {
    .mainCard {
        /* aspect-ratio: 390/669 !important; */
        height: 75%;
        /* padding: 20px 12px; */
    }

    .dice {
        gap: 4%
    }

    /* .group2 {
        gap: 2%
    } */

    .card-text {
        height: 8.3% !important;
    }

    .inputSliderDiv {
        height: 40% !important;
        justify-content: center !important;
    }

    .group4 {
        height: 25% !important;
        padding: 2% !important;
        gap: 6px !important;
        justify-content: center;
    }
}

@media screen and (max-height: 610px) {
    .dice {
        gap: 3%;
    }
}

.inputSliderDiv {
    display: flex;
    align-items: center;
}

.headingRoll {
    background-image: linear-gradient(to right, #FFD809 0%, #FF8A00 100%);
    /* font-size: 5.14286em; */
    font-size: clamp(1rem, 1.1505rem + 5.7184vh, 4.5rem);
    font-weight: 400;
    text-align: center;
    font-family: var(--fontFamilyFredokaOne);
    width: 100%;
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    padding: 0;
    max-height: 86px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}


/* BET HISTORY */

.bet-content {
    font-family: var(--fontFamilyFredoka);
    font-size: var(--fontSize18);
    font-weight: 400;
    line-height: 24px;
    text-align: center;
}

.bets-history {
    /* margin: 0 auto; */
    width: 100%;
    overflow: hidden;
    text-align: right;
    direction: rtl;
    /* max-width: 436px; */
    display: flex;
    max-height: 36px;
    /* height: 100%; */
    gap: 1.25rem
}

.individual-bet {
    gap: 8px;
    border-radius: 8px;
    border: 1px;
    text-decoration: none;
    max-width: 4.5rem;
    direction: rtl;
    max-height: 2.25rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: clamp(8px, 2.5vh, 18px);
    cursor: pointer;
    padding: 1% 5%;
}

.bets-history .individual-bet span {
    opacity: 50%;
}

/* Chance */
.card-text {
    box-shadow: 0px -0.5px 2.5px 0.5px #FFFFFF1A;
    background: #061B33;
    padding: 2.4% 1.7%;
    border-radius: 8px;
    height: 11.37%;
    align-items: center;
}

.chanceTxt {
    font-family: var(--fontFamilyFredoka);
    font-size: var(--fontSize14h);
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.02em;
    text-align: left;
    color: #9CA3AF;
}

.chanceWin {
    font-family: var(--fontFamilyFredokaOne);
    font-size: var(--fontSize18h);
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    color: #F9FAFB;
    opacity: 70%;
}

.group4 {
    max-height: 109px;
    background: #061B33;
    box-shadow: 0px -0.5px 2.5px 0.5px #FFFFFF1A;
    padding: 2.614%;
    gap: 12px;
    border-radius: 8px;
    border: 2px 2px 0px 2px;
    display: flex;
    flex-direction: column;
    height: 28.2%;
}

.targetPayout {
    font-family: var(--fontFamilyFredoka);
    font-size: var(--fontSize14h);
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.02em;
    text-align: left;
    color: #9CA3AF;
}

.targetPayoutValue {
    font-family: var(--fontFamilyFredoka);
    font-size: var(--fontSize18h);
    font-weight: 600;
    line-height: 24px;
    text-align: left;
    color: #FFFFFF;
}

.bottomValue {
    font-family: var(--fontFamilyFredokaOne);
    font-size: var(--fontSize20h);
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    background: #061B33;
    width: 100%;
    color: #ffffffc7;
    border: none;
    border-color: transparent;
    outline: none;
    height: 100%;
}

.betValueDiv {
    background: #061B33;
}

.bottomGroup {
    height: 10%;
    background: #061B33;
    box-shadow: 0px -0.5px 2.5px 0.5px #FFFFFF1A;
    border-radius: 8px;
}

.guessTxt {
    font-family: var(--fontFamilyFredokaOne);
    font-size: var(--fontSize18h);
    font-weight: 400;
    line-height: 28px;
    letter-spacing: 0em;
}

.guessMux {
    font-family: var(--fontFamilyNunito);
    font-size: var(--fontSize14h);
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0em;
}

.progressBarNum {
    font-family: var(--fontFamilyNunito);
    font-size: var(--fontSize14h);
    font-weight: 500;
    line-height: 20px;
    text-align: center;
    color: #FFFFFF;
    opacity: 40%;
}

@media screen and (max-height: 570px) {
    .dice {
        gap: 2%;
    }

    .mainCard {
        height: 70%;
    }

    .bets-history {
        max-height: 1.3rem !important;
    }

    .individual-bet {
        font-size: clamp(8px, 2vh, 18px) !important;
    }

    .headingRoll {
        height: 60% !important;
    }

    .progressBarNum {
        font-size: clamp(8px, 2vh, 14px);

    }

    .group2 {
        margin-bottom: 0rem !important;
    }
}

@media screen and (min-height: 500px) {
    .bets-history {
        margin-top: 2%;
    }
}

.chancep {
    margin: unset;
}

.border1 {
    border: 1px solid #384562
}

.div1 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 16px;
}

.div2 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 50%;
}

.div3 {
    border-left: 1px solid #384562;
    height: 24px;
    width: 1px;
}

.payoutP {
    display: flex;
    align-items: center;
    margin: unset;
    gap: 4px;
}

.label1 {
    color: #FFFFFF
}

.div4 {
    display: flex;
    justify-content: center;
    gap: 4px;
}