.pageNumber {
    display: inline-block;
    padding: 5px 10px;
    margin: 0 5px;
    border: 1px solid #4B5563;
    border-radius: 6px;
    cursor: pointer;
    background: #1F2937;
    font-family: var(--fontFamilyNunito);
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    text-align: center;
}

.pageNumber:hover {
    background-color: #7f8793;

}

.paginationContainer {
    display: flex;
    justify-content: center;
}

.selectedPage {
    background: #4B5563;
    font-family: var(--fontFamilyNunito);
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    text-align: center;
    color: #FFFFFF;
}

.blur {
    filter: blur(2px);
}