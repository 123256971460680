.tabs {
    max-width: 1118.5px;
    height: 3rem;
    padding: 4px;
    gap: 8px;
    border-radius: 8px;
    opacity: 1px;
    background: #1C2939;
    margin: 0;
    display: flex;
}

.tableHeading {
    width: 27px;
    height: 24px;
    left: 326px;
    gap: 0px;
    opacity: 1px;
    font-family: var(--fontFamilyFredoka);
    font-size: 14px;
    font-weight: 500 !important;
    line-height: 24px;
    text-align: left;
    background: none;
    text-transform: uppercase;
}

.tableBody {
    max-width: 53px;
    height: 24px;
    font-family: var(--fontFamilyFredoka);
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    background: linear-gradient(180deg, #2C3847 0%, #222C38 100%) !important;
    text-transform: uppercase;
    width: 1118.5px;
    padding: 8px 10px 8px 10px;
    gap: 12px;
    opacity: 1px;
    border-collapse: separate;
    cursor: pointer;
}

.tableRow {
    border-collapse: separate;
    border-radius: 10px;
    /* Adjust the values as needed */
}

.table {
    border-collapse: separate;
    border-spacing: 0 5px;
    overflow-x: auto;
    width: 100%;
    overscroll-behavior: contain;
}

.table th {
    font-weight: 500;
    padding: 0 0 0 1%;
    border-bottom: 1px solid rgba(203, 188, 229, 0.21);
}

.tableRow td {
    padding: 10px;
}

.names {
    font-weight: 500;
}

@keyframes slideInOddAnimation {
    0% {
        transform: translate(0px, -100%);
    }
}

@keyframes slideInEvenAnimation {
    0% {
        transform: translateY(-100%);
    }
}

@keyframes slidInNewAnimation {
    0% {
        transform: translateY(-20%);
    }
}

@media screen and (max-width: 440px) {
    .tabs {
        height: 4rem !important;
    }

    .table {
        width: 135%;

    }
}

.borderBottom {
    border-bottom: 1px solid rgba(203, 188, 229, 0.21);
    /* Opacity set to 21% */
}

.profileImage {
    border-radius: 50%
}

.td1 {
    border-collapse: separate;
    border-radius: 10px 0px 0px 10px;
    padding: 8px;
    font-weight: 500;
    white-space: nowrap;
}

.img1 {
    padding: 2px;
    height: 24px;
    width: 24px;
}

.td2 {
    padding: 8px;
    white-space: nowrap;

}

.td3 {
    border-collapse: separate;
    border-radius: 0px 10px 10px 0px;
}

.img2 {
    padding: 2px;
    float: right;
    margin: 0 7% 0 0;
    object-fit: contain;
}

.thiniti {
    float: initial;
}

.thright {
    float: right;
    width: 100%;
    text-align: end;
}