.switch {
    position: relative;
    display: inline-block;
    width: 36px;
    height: 20px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    /* background-color: #ccc; */
    background: linear-gradient(0deg, #434B55, #434B55);

    -webkit-transition: .4s;
    transition: .4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 16px;
    width: 16px;
    left: 3px;
    bottom: 2px;
    background: rgba(113, 124, 140, 1);
    box-shadow: 0px 1px 3px 0px rgba(16, 24, 40, 0.1);
    -webkit-transition: .4s;
    transition: .4s;
}

input:checked+.slider {
    background: linear-gradient(180deg, #FBAE56 0%, #F28100 100%);

}

input:focus+.slider {
    box-shadow: 0 0 1px #2196F3;
}

input:checked+.slider:before {
    -webkit-transform: translateX(14px);
    -ms-transform: translateX(14px);
    transform: translateX(14px);
    background: rgba(249, 250, 251, 1);
    box-shadow: 0px 1px 3px 0px rgba(16, 24, 40, 0.1);

}

/* Rounded sliders */
.slider.round {
    border-radius: 34px;

}

.slider.round:before {
    /* width: 16px; */
    /* height: 16px; */
    border-radius: 50%;
}

.connections {
    border-bottom: 1px solid rgba(71, 81, 93, 1)
}

.connectionTxt {
    font-family: var(--fontFamilyNunito);
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    text-align: center;
    color: rgba(255, 255, 255, 1);
    text-transform: capitalize;
}

.connectionString {
    font-family: var(--fontFamilyNunito);
    font-size: 18px;
    font-weight: 600;
    line-height: 28px;
    text-align: left;
    color: rgba(255, 255, 255, 1);

}