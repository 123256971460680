.comment-main
  overflow-wrap: anywhere
  max-width: 100%
  padding: 1rem
  border-top: 1px solid rgba(209, 212, 219, 0.25)
  border-bottom: 1px solid rgba(209, 212, 219, 0.25)

  .comment-name
    color: #FFFFFF
    margin-right: 4px
    text-transform: titlecase
    font-family: var(--fontFamilyNunito)
    font-weight: 700

  .messageColor
    color: #FFFFFF
    opacity: 70%
    font-weight: 400
    font-family: var(--fontFamilyNunito)
