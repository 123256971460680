.bets-history
  margin: 0 auto
  width: 100%
  overflow-x: hidden
  text-align: right
  direction: rtl
  margin: 0 auto
  display: flex
  min-height: 36px
  overflow: hidden

  .individual-bet
    // padding: 6px 8px
    border-radius: 8px
    // margin-right: 4px
    // margin-bottom: 4px
    display: inline-block
    text-decoration: none
    width: 16.7%
    max-width: 72px
    text-align: center
    direction: rtl
    height: 100%
    max-height: 36px
    align-items: center
    display: flex
    justify-content: center
    span
      opacity: 0.5

  @media screen and (max-width: 690px)
    .individual-bet
      height: fit-content
