.aboutTheGame {
    max-width: 955px;
    height: 262px;
    padding: 0px 16px 0px 16px;
    gap: 32px;
    margin: auto;
    margin-bottom: 5rem;
}

.about {
    gap: 23px;
}

.aboutHeading {
    font-family: var(--fontFamilyFredoka);
    font-size: 22px;
    font-weight: 600;
    line-height: 26.62px;
    text-align: left;
    color: #FFFFFF;
    text-transform: uppercase;
}

.content {
    font-family: var(--fontFamilyNunito);
    font-size: 16px;
    font-weight: 700;
    line-height: 26px;
    text-align: left;
    color: #828F9F;
    gap: 2rem;
    display: flex;
    flex-direction: column;
}

.imageDiv {
    border-radius: 16px;
    /* width: 18.194vw; */
    /* height: 33.547vh; */
    background: #20272F;
    justify-content: center;
    display: flex;
}

@media screen and (max-width: 1066px) {
    .aboutTheGame {
        margin-right: 5rem;
        margin-left: 5rem;
    }
}

@media screen and (max-width: 954px) {
    .aboutTheGame {
        margin: auto;
        margin-bottom: 5rem;
    }
}

@media screen and (max-width: 750px) {
    .aboutTheGame {
        display: flex;
        flex-direction: column;
        height: max-content;
    }
}

.image1 {
    border-radius: 16px;
    border: transparent;
    object-fit: contain;
}

.div1 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 23px;
}