.input {
    max-width: 497px;
    width: 100%;
    height: 44px;
    padding: 3%;
    gap: 8px;
    border-radius: 12px;
    background: #1F2937;
    border: 1px solid #4B5563;
    font-family: var(--fontFamilyNunito);
    font-size: clamp(14px, 2vw, 16px);
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    color: #FFFFFF;
}

.relative {
    position: relative;
}