.gameBg {
    /* background: #21252F; */
    border-radius: 8px;
    width: 100%;
    height: 32vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-repeat: no-repeat;
    background-position: center;
}

.playNow {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.username {
    text-transform: uppercase;
    cursor: pointer;
}

.score {
    font-family: var(--fontFamilyNunito);
    font-size: clamp(12px, 1.5vw, 16px);
    font-weight: 700;
    line-height: 21.82px;
    text-align: left;
    opacity: 60%;
}

.result {
    float: right;
    font-family: var(--fontFamilyNunito);
    font-size: clmap(12px, 1.5vw, 16px);
    font-weight: 700;
    line-height: 21.82px;
    text-align: right;

}

.betId {
    font-family: var(--fontFamilyNunito);
    font-size: clamp(12px, 1.5vw, 18px);
    font-weight: 600;
    line-height: 28px;
    text-align: left;

}

.social {
    gap: 5%;
    opacity: 50%;
    float: right;
    display: flex;
    justify-content: flex-end;
    align-self: center;
    cursor: pointer;
}

.wintxt {
    width: 58px;
    height: 36px;
    padding: 6px 12px;
    gap: 8px;
    border-radius: 8px;
    border: 1px;
    border: 1px solid var(--color-green-500, #2DCA72);
    justify-content: center;
    display: flex;
    align-items: center;
}

.losstxt {
    width: 58px;
    height: 36px;
    padding: 6px 12px;
    gap: 8px;
    border-radius: 8px;
    border: 1px;
    border: 1px solid var(--color-green-500, #ca4f2d);
    justify-content: center;
    display: flex;
    align-items: center;
}

.winloss {
    font-family: var(--fontFamilyFredoka);
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    text-align: center;
    background: var(--color-green-500, #2DCA72);
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    background-clip: text;
    margin: auto;
}

.loss {
    font-family: var(--fontFamilyFredoka);
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    text-align: center;
    background: var(--color-green-500, #ca4f2d);
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    background-clip: text;
    margin: auto;
}

.tag {
    padding: 0px 6px 0px 6px;
    gap: 4px;
    border-radius: 6px;
    background: #452F1A;
    border: 1px solid #896030;
}

.tagtxt {
    background: linear-gradient(180deg, #FBAE56 0%, #F28100 100%);
    font-family: var(--fontFamilyNunito);
    font-size: clamp(12px, 1.5vw, 14px);
    font-weight: 700;
    line-height: 22px;
    letter-spacing: -0.03em;
    text-align: center;
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    background-clip: text;
    text-transform: uppercase;
}

.profileIcon {
    border-radius: 50%;
}

.img1 {
    object-fit: contain;
}

.bgImg {
    position: absolute;
    filter: brightness(0.5);
}