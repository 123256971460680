.content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 2rem;
    padding: 0 2%;
}

.content p {
    font-family: var(--fontFamilyNunito);
    font-size: clamp(10px, 2vh, 16px);
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    color: #FFFFFF;
}

.title {
    background: linear-gradient(180deg, #FFE30B 0%, #FFA627 100%),
        linear-gradient(180deg, #9AFF96 0%, #1AA414 85.42%),
        linear-gradient(180deg, #FFD809 0%, #FF8A00 85.42%);
    font-family: var(--fontFamilyFredokaOne);
    font-size: 32px;
    font-weight: 400;
    line-height: 38.4px;
    text-align: center;
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    background-clip: text;
}