.changePasswordTxt {
    font-family: var(--fontFamilyNunito);
    font-size: 18px;
    font-weight: 600;
    line-height: 28px;
    text-align: left;
    color: rgba(255, 255, 255, 1);
    cursor: pointer;
}

.changePassword {
    border-bottom: 1px solid rgba(71, 81, 93, 1) !important;
    /* padding: 24px; */
    padding: 0 4% 4% 4%;
    gap: 12px;
    border: 0px 0px 1px 0px;
}