.appHeader {
    padding: 12px;
    opacity: 1px;
    border: 1px solid #272d4a;
    border-left: none;
    background: #1F2937;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 2;
    height: 64px;
    border-bottom: 1px solid #484F60;
    align-items: center;
}

.navLogo {
    width: 12.364rem
}

@media screen and (max-width: 426px) {

    .tokenDiv {
        display: none;
    }

    .navLogo {
        width: 7.875rem
    }

}

.div1 {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 99;
    background: #000;
}

.div2 {
    gap: 16px;
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: none;
}

@media screen and (max-width: 426px) {
    .div2 {
        padding: auto;
    }
}

.img1 {
    width: 30px;
    height: 30px;
}

.div3 {
    display: flex;
    align-items: center;
    justify-content: end;
    gap: 5px;
    cursor: pointer;
}