.center {
    /* position: fixed; */
    /* top: 8.125rem; */
    /* margin-top: 8.125rem; */
    /* left: 50%;
    top: 55%;
    transform: translate(-50%, -50%); */
    top: 10%
}

.footer {
    position: fixed;
    bottom: 0;
    width: 100%;
}

.box {
    width: 23.75rem;
    width: clamp(17.75rem, 44vh, 23.75rem);
    max-height: 35.25rem;
    gap: 24px !important;
    display: flex;
    flex-direction: column;
    position: relative;
}

.title {
    font-family: var(--fontFamilyNunito);
    font-size: clamp(22px, 3vh, 30px);
    font-weight: 600;
    line-height: 38.19px;
    text-align: center;
}

.subtitle {
    font-family: var(--fontFamilyNunito);
    /* font-size: 16px; */
    font-size: clamp(10px, 2vh, 16px);
    font-weight: 600;
    line-height: 24px;
    text-align: center;
    color: #9CA3AF;
}

.discordText {
    font-family: var(--fontFamilyNunito);
    font-size: 16px;
    font-size: clamp(10px, 2vh, 16px);
    font-weight: 700;
    line-height: 24px;
    text-align: center;
}

.line {
    width: 166px;
    height: 0px;
    gap: 0px;
    opacity: 20%;
    border: 1px solid #FFFFFF
}

.or {
    font-family: var(--fontFamilyNunito);
    font-size: 16px;
    font-size: clamp(10px, 2vh, 16px);
    font-weight: 700;
    line-height: 24px;
    text-align: left;
    width: 16px;
    height: 24px;
    gap: 0px;
    opacity: 70%;
}

.input {
    max-width: 380px;
    max-height: 44px;
    width: 100%;
    gap: 8px;
    border-radius: 12px;
    background: #1F2937;
    border: 1px solid #4B5563;
    color: #FFFFFF;
    -webkit-text-fill-color: #FFFFFF;
    font-size: 16px;
    font-size: clamp(10px, 2vh, 16px);
    font-family: var(--fontFamilyNunito);
    opacity: 60%;
    font-weight: 600;
}

.input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 1000px #1F2937 inset !important;
}

.inputContainer {
    position: relative;
    display: inline-block;
    width: 100%;
}


.eyeIcon {
    position: absolute;
    top: 50%;
    right: 10px;
    /* Adjust as needed */
    transform: translateY(-50%);
    width: 20px;
    /* Adjust icon size as needed */
    height: 20px;
    cursor: pointer;
}

.authFoot {
    opacity: 70%;
    font-family: var(--fontFamilyNunito);
    font-size: 14px;
    font-size: clamp(10px, 1.7vh, 14px);
    font-weight: 400;
    line-height: 19.6px;
    text-align: center;
    color: #FFFFFF;
}

.authFootIn {
    font-weight: 800;
}

.inputContainer {
    position: relative;
}

.inputIcon {
    position: absolute;
    top: 46%;
    transform: translateY(-50%);
    left: 10px;
    /* Adjust position as needed */
    width: clamp(8px, 2.8vh, 20px);
    /* Adjust icon size as needed */
    height: clamp(8px, 2.8vh, 20px);
}

/* .inputEmail { */
/* padding-left: 40px !important; */
/* padding-left: 10.5%; */
/* Adjusted padding to accommodate the icon */
/* width: calc(100% - 35px); */
/* Adjusted width to account for the icon */
/* } */
.chevron {
    width: clamp(8px, 2.8vh, 20px);
    height: clamp(8px, 2.8vh, 20px);

}

/* .inputEmail { */
/* padding-left: 40px !important; */
/* padding-left: 10.5%; */
/* Adjusted padding to accommodate the icon */
/* width: calc(100% - 35px); */
/* Adjusted width to account for the icon */
/* } */

@keyframes shake1 {
    0% {
        margin-left: 0rem;
    }

    25% {
        margin-left: 0.5rem;
    }

    75% {
        margin-left: -0.5rem;
    }

    100% {
        margin-left: 0rem;
    }
}

@keyframes shake2 {
    0% {
        transform: translate(1px, 1px) rotate(0deg);
    }

    10% {
        transform: translate(-1px, -2px) rotate(-1deg);
    }

    20% {
        transform: translate(-3px, 0px) rotate(1deg);
    }

    30% {
        transform: translate(3px, 2px) rotate(0deg);
    }

    40% {
        transform: translate(1px, -1px) rotate(1deg);
    }

    50% {
        transform: translate(-1px, 2px) rotate(-1deg);
    }

    60% {
        transform: translate(-3px, 1px) rotate(0deg);
    }

    70% {
        transform: translate(3px, 1px) rotate(-1deg);
    }

    80% {
        transform: translate(-1px, -1px) rotate(1deg);
    }

    90% {
        transform: translate(1px, 2px) rotate(0deg);
    }

    100% {
        transform: translate(1px, -2px) rotate(-1deg);
    }
}

@keyframes shake3 {
    0% {
        transform: translate(1px, 1px) rotate(0deg);
    }

    20% {
        transform: translate(-3px, 0px) rotate(1deg);
    }

    40% {
        transform: translate(1px, -1px) rotate(1deg);
    }

    60% {
        transform: translate(-3px, 1px) rotate(0deg);
    }

    90% {
        transform: translate(1px, 2px) rotate(0deg);
    }

    100% {
        transform: translate(1px, -2px) rotate(-1deg);
    }
}

@keyframes shake4 {

    0%,
    100% {
        transform: translateX(0);
    }

    10%,
    30%,
    50%,
    70%,
    90% {
        transform: translateX(-10px);
    }

    20%,
    40%,
    60%,
    80% {
        transform: translateX(10px);
    }
}

@keyframes shake5 {
    0% {
        transform: rotate(0deg);
    }

    25% {
        transform: rotate(5deg);
    }

    50% {
        transform: rotate(0deg);
    }

    75% {
        transform: rotate(-5deg);
    }

    100% {
        transform: rotate(0deg);
    }
}

@keyframes shake6 {
    0% {
        transform: translateX(0);
    }

    25% {
        transform: translateX(5px);
    }

    50% {
        transform: translateX(-5px);
    }

    75% {
        transform: translateX(5px);
    }

    100% {
        transform: translateX(0);
    }
}

@keyframes shake7 {
    0% {
        transform: translate(0, 0) rotate(0deg);
    }

    25% {
        transform: translate(5px, 5px) rotate(5deg);
    }

    50% {
        transform: translate(0, 0) rotate(0deg);
    }

    75% {
        transform: translate(-5px, 5px) rotate(-5deg);
    }

    100% {
        transform: translate(0, 0) rotate(0deg);
    }
}

input:invalid {
    animation: shake3 0.4s ease-in-out 0s 2;
    color: #ff9494;
    border: 2px solid #ff9494;
    border-radius: 12px;
}

.error {
    animation: shake3 0.4s ease-in-out 0s 2;
    color: #ff9494 !important;
    border: 2px solid #ff9494 !important;
    max-width: 380px;
    max-height: 44px;
    width: 100%;
    /* padding: 10px 16px 10px 16px; */
    padding: 3% 3% 3% 10.5%;
    gap: 8px;
    border-radius: 12px;
    background: #1F2937;
    -webkit-text-fill-color: #FFFFFF;
    font-family: var(--fontFamilyNunito);
    opacity: 60%;
    font-weight: 700;
    font-size: 16px;
    font-size: clamp(10px, 2vh, 16px);
}

/* .notError {
    border: 2px solid #c2ff94 !important;
} */

.inputPadding {
    padding: 3% 3% 3% 10.5% !important;
}

.inputPadding2 {
    padding: 3% 3% 3% 3% !important;
}

.otpInput {
    max-width: 53.33px;
    max-height: 47px;
    width: 100%;
    padding: 3% 4.8%;
    border-radius: 12px;
    background: #252D36;
    border: 1px solid #4B5563;
    text-align: center;
    -webkit-text-fill-color: #FFFFFF;
    font-family: var(--fontFamilyNunito);
    font-size: 20px;
    font-weight: 700;
    line-height: 27.28px;
    opacity: 60%;
    caret-color: #FFFFFF;
}

.otpInput:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 1000px #1F2937 inset !important;
    /* Set the background color */
}

.inputContainerOtp {
    max-width: 380px;
    max-height: 47px;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type="number"] {
    -moz-appearance: textfield;
}

@media screen and (max-width: 426px) {
    .box {
        width: 21.875rem !important;
    }

    .input {
        width: 100%;
    }

}

.authSwitch {
    font-family: var(--fontFamilyNunito);
    font-size: clamp(10px, 2vh, 14px);
    font-weight: 400;
    line-height: 19.6px;
    text-align: center;
    color: #9CA3AF;
}

.authSwitchLink {
    color: #f28100;
    text-decoration: underline;
    font-size: clamp(12px, 1.3vw, 14px);
}

.authFootForg {
    color: #9CA3AF;
    opacity: 100%;
}

.authForgBox {
    top: 0 !important;
    margin-top: 0;
}

.support {
    cursor: pointer;
    font-family: var(--fontFamilyNunito);
    font-weight: 700;
    font-size: clamp(8px, 2vh, 16px);
    color: #F9FAFB;
    align-items: center;
    bottom: 4rem;
}

.infoIcon {
    height: clamp(8px, 2.8vh, 24px);
}

.contactDiv {
    max-width: 155px;
    max-height: 24px;
    top: 20px;
    gap: 0.5rem;
    /* align-items: center; */
    justify-content: end;
    display: flex;
    flex-direction: row;
}

@media screen and (max-width: 320px) {
    .support {
        font-size: 14px !important;
    }

    .box {
        width: 17.875rem !important;
    }
}

.forgotContinueSpan {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1%;
    height: 60%;
}

.authHeaderMooncake {
    height: clamp(10px, 4vh, 30px);
    z-index: 9;
    cursor: pointer;
}

.otpInputsGap {
    gap: 3.15%;
    display: flex;
    justify-content: center;
    align-items: center
}

.div1 {
    padding: 20px 8px;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.signDiv1 {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 12px;
    max-height: 100px;
    padding-top: 12px;
}

.signDiv2 {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 16px;
    height: 24px;
}

.signDiv3 {
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 12px;
}

.signDiv4 {
    text-decoration: underline;
    gap: 8px;
    text-align: start;
    cursor: pointer;
}

.signupP1 {
    opacity: 60%;
}

.otpDiv1 {
    gap: 2px;
    display: flex;
    flex-direction: column;
}

.backIcon {
    position: absolute;
    left: 3%;
    cursor: pointer;
}