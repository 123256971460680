.loginHistory {
    gap: 12px;
    display: flex;
    flex-direction: column;
    border: 0px 0px 1px 0px;
    border-bottom: 1px solid rgba(71, 81, 93, 1)
}

.loginHistoryTxt {
    font-family: var(--fontFamilyNunito);
    font-size: 18px;
    font-weight: 600;
    line-height: 28px;
    text-align: left;
    color: rgba(255, 255, 255, 1);
}

.p {
    font-family: var(--fontFamilyNunito);
    font-size: clamp(14px, 2vw, 16px);
    font-weight: 800;
    line-height: 24px;
    text-align: left;
    color: rgba(249, 250, 251, 1);
    padding: 16px 20px 16px 20px;
}

.th {

    border-width: 1px, 0px, 0px, 1px;
    border-style: solid;
    border-color: rgba(75, 85, 99, 1);
    background: rgba(55, 65, 81, 1);
    max-width: 118px;
    max-height: 80px;
    gap: 0px;
    border: 1px 0px 0px 1px;
}

.th:last-child p {
    text-align: end;
}

.tableH {
    border-collapse: separate;
    border-spacing: 0;
}

.tableH tr th,
.tableH tr td {
    border-right: 1px solid rgba(75, 85, 99, 1);
    border-bottom: 1px solid rgba(75, 85, 99, 1);
    color: #F9FAFB;
}

.tableH tr th:first-child,
.tableH tr td:first-child {
    border-left: 1px solid rgba(75, 85, 99, 1);
}

.tableH tr th {
    color: #eee;
    text-align: left;
    border-top: solid 1px rgba(75, 85, 99, 1);
    min-width: 212.33px;
}

/* top-left border-radius */
.tableH tr:first-child th:first-child {
    border-top-left-radius: 6px;
    min-width: 83px;
}

/* top-right border-radius */
.tableH tr:first-child th:last-child {
    border-top-right-radius: 6px;
}

/* bottom-left border-radius */
.tableH tr:last-child td:first-child {
    border-bottom-left-radius: 6px;
}

/* bottom-right border-radius */
table tr:last-child td:last-child {
    border-bottom-right-radius: 6px;
}

.tbodyH {
    background: rgba(31, 41, 55, 1);
    font-family: var(--fontFamilyNunito);
    font-size: var(--fontSize16w);
    font-weight: 600;
    line-height: 24px;
    text-align: left;
    color: rgba(255, 255, 255, 1);
}

.tbodyH tr td {
    padding: 10px;
}

.tbodyH tr td:last-child {
    text-align: end;
}

.tbodyH tr td:first-child {
    text-align: center;
}

.noMoreData {
    text-align: center !important;
}

.loginHistoryTableDiv {
    max-width: 472px;
}