ul {
    padding-left: 0rem;
}

.navbar {
    background: #1F2937 !important;
    z-index: 3;
    border-width: thin;
    border-right: 1px solid #D1D4DB;
    border-color: #D1D4DB33;
    top: 64px;
    position: fixed;
    height: 100%;
    flex: 4;
}

.openSidebar {
    display: flex;
}

@media screen and (max-width: 993px) {
    .navbar {
        z-index: 99;
        width: 100%;
    }

    .closeSidebar {
        display: none;
    }
}

.menu-list {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    width: 100%;
    margin-top: 20px;
    align-items: end;
}

.menu-list .menu-item {
    width: 100%;
    list-style: none !important;
    border: 1px solid;
    padding: 3px 15px;
    border-radius: 7px;
}

/* .active {
    min-width: 160px;
    background: #1F2937;
} */

.float-child:hover {
    background: rgba(255, 255, 255, 0.05);
    border-radius: 5px;
    font-family: var(--fontFamilyNunito);

}

.float-child {
    align-self: center;
    transition: all 0.5s ease;
    color: #FFFFFF;
    font-family: var(--fontFamilyNunito);
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    text-align: left;
    /* background-color: rgba(255, 255, 255, 0.2); */
    border-radius: 5px;
    cursor: pointer;
}

.float-container {
    max-width: 44px;
    height: 44px;
    justify-content: flex-end;
    border-radius: 5px;
    width: 100%;
}

.float-container-open {
    border-radius: 5px;
    background: rgba(255, 255, 255, 0.05);
    width: 136px;
    height: 44px;
    justify-content: flex-start;
    padding-left: 1rem;
}


@media (max-width: 1300px) {
    .float-container-open {
        width: 100%;
        min-width: 8rem;
    }
}

.sideNav {
    width: 160px !important;
    border-width: 0px, 1px, 1px, 0px !important;
    border-style: solid !important;
    border-color: #D1D4DB33 !important;
    background: #1f2937 !important;
}

.support {
    position: fixed;
    margin: 0 0 1rem 0;
    justify-content: center;
    padding: 0 !important;
    margin-right: 0 !important;
    margin-bottom: 5% !important;
    bottom: 4rem;
}

.navTo {
    text-decoration: none !important;
    display: flex;
    align-items: center;
}