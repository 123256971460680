#playing-area
  display: flex
  justify-content: center
  align-items: center

.diceWon
  background-image: linear-gradient(to right, #2eaf29 0%, #FF8A00 100%)

.diceLoss
  background-image: linear-gradient(to right, #eb5c0e 0%, #FF8A00 100%)

.headingalignew
  display: flex
  justify-content: flex-end

.headingalign
  display: flex
  justify-content: space-between

.headingGradient3
  border-radius: 4px
  display: flex
  justify-content: space-between
  align-items: center
  background-image: linear-gradient(to right, #3c2162 0%, #2a0c53 100%)
  color: #fff
  // width: 212px
  height: 32px
  padding: 4px 12px 4px 12px
  border: 1px

.ProgressBarmeter
  display: flex
  color: #bbb
  justify-content: space-between

.progress
  max-width: 97%
  margin: auto
  box-shadow: inset 0 1 1px #000000
  --bs-progress-height: clamp(30%, 1.3vh, 50%)

// @media screen and (max-width: 425px)
//   .progress
//     height: 35%

.progress-bar
  //background: linear-gradient(180deg, #46D640 0%, #2BB426 100%)
  // box-shadow: inset 0 0 1px #000000
  border-top-right-radius: 80px 80px
  border-bottom-right-radius: 80px 80px
  transition: none

.input-slider
  width: 100%
  margin: 0px 0.5rem

.submits
  background-image: linear-gradient(to right, #FFCA68 0%, #E98E07 100%)
  width: 100%
  padding: 8px
  font-weight: bold

.btngreen
  margin: unset
  font-size: large
  font-weight: bold
  color: #fff

.btngreen1
  margin: unset
  font-size: small
  color: #fff

.btnblue
  margin: unset
  font-size: large
  font-weight: bold
  color: #fff
  display: flex
  align-items: center

.btnblue1
  margin: unset
  font-size: small
  color: #fff

.badge
  width: 55px
  font-weight: 200
  background-image: linear-gradient(to right, #5004b9 0%, #3A4556 100%)

.maincardtable
  background: #190b2d
  width: 100%
  margin: 7rem 3rem
  padding: 10px

.headingGradienttable
  color: #FFD809
  text-align: center
  // margin-top: 25px
  font-weight: bolder
  font-size: 1.6rem

.table
  margin: 25px 50px 30px 20px
  width: 95%
  --bs-table-bg: #241737
  --bs-table-color: #fff
  --bs-table-hover-color: #bbb
  font-family: cursive

.dollar
  background: orange
  width: 22px
  border-radius: 50%
  height: 22px
  display: inline-flex
  justify-content: center
  align-items: center
  color: #000
  font-weight: bold
  margin: 3px

.logo
  width: 17rem
  position: absolute
  top: -49px
  left: 20%

.logos
  width: 4rem
  position: absolute
  top: -60px
  right: 4%

.betButtons
  background-image: linear-gradient(to bottom, #9EABBA -220%, #2a0c53 100%)
  border-radius: 0px
  text-align: center
  border: 0px
  --bs-btn-color: #ffffff8f
  width: 48px
  height: 48px
  padding: 12px
  margin: 0px 0.25rem

.betButtonAngles
  display: flex
  flex-direction: column
  align-items: center

input[type="range"]
  -webkit-appearance: none
  cursor: pointer
  height: 4px
  display: flex
  position: relative
  background: #66728F
  border-radius: 52px
  border: 1.14px

  /***** Thumb Styles *****/
/***** Chrome, Safari, Opera, and Edge Chromium *****/
input[type="range"]::-webkit-slider-thumb
  -webkit-appearance: none
  appearance: none
  margin: 0px 8px
  border: 1px
  border-radius: 50%
  -moz-box-shadow: inset 0 -10px 10px -10px #000
  -webkit-box-shadow: inset 0 -10px 10px -10px #000
  box-shadow: 0px -1px 1.2px 0px #5B3800 inset
  width: 22px
  height: 20px
  top: 8px
  left: 95.26px
  background: #FFAC2A

.glowButton
  box-shadow: 0px 0px 10px 1px yellow

.cardBorderGradient
  border-image: linear-gradient(transparent 2%, #FFDC6B 32% 0%, transparent 50%) 0 1 0 1/7px

.cardBorderGradientTop
  padding-top: 7px
  background: linear-gradient(90deg, rgba(131,58,180,0) 10%, #FFDC6B 50%, rgba(69,252,244,0) 90%)

.card-text
  margin: 0px 3px

.mt-4
  margin-top: 12px !important
  display: flex
  height: 30px
  gap: 12px

.card-body-dice
  max-width: 460px
  //max-height: 533px

.betButtonDiv
  padding: 12px 12px 12px 12px
