.gddiv {
    display: flex;
    gap: 8px;
    justify-content: center;
}

.div1 {
    flex-direction: row;
}

@media screen and (max-width: 770px) {
    .div1 {
        flex-direction: column;
    }

}