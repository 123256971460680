.p-0 {
    padding: 0;
    border-collapse: separate;
    border-radius: 10px 0px 0px 10px;
    font-weight: 700;
    font-family: var(--fontFamilyNunito);
    font-size: clamp(10px, 2.5vw, 14px);
}

.p-1 {
    display: flex;
    align-items: center;
    gap: 4px;
    font-family: var(--fontFamilyNunito);
    font-weight: 500;
    justify-content: flex-end;
}

.p-2 {
    justify-content: flex-start !important;
}

.div-1 {
    background: linear-gradient(180deg, #2C3847 0%, #222C38 100%);
    border-radius: 12px;
    padding: 12px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.infinite-1 {
    overflow-y: auto;
    overflow-x: clip;
    width: 100%;
}

.tabIFp1 {
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 4px;
    font-family: var(--fontFamilyNunito);
    font-weight: 500;
}

.tabIFImg1 {
    object-fit: contain;
}

.tabIFth1 {
    font-weight: 500;
    padding: .4rem 0.3rem;
    font-size: clamp(10px, 2.5vw, 14px);
}