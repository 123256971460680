.newli {
    display: flex;
    justify-content: space-between;
    background: #100525;
    margin: 5px;
    padding: 5px;
    border-radius: 5px;
    font-size: 0.875em;
    font-family: var(--fontFamily)
}

.customTokenDropdown {
    right: 0px !important;
    left: auto !important;
}

@media screen and (max-width: 992px) {
    .dropdownMenuTablet {
        position: relative !important;
        transform: translate3d(0px, 0px, 0px) !important;
        width: 100%;
    }
}

.dropdownToggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: none;
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent;
}


.customd .dropdownMenu {
    padding: 10px;
    cursor: pointer;
    background: #161f42;
    color: #fff;
    min-width: 400px;
    margin-top: 7px !important;
}

.tokenSelector {
    background: var(--secondaryBtnColor);
    color: #fff;
    padding: 3px 8px;
    border-radius: 8px;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
    /* width: 25rem; */
    border-style: solid;
    border-width: thin;
    border-color: #2b2e46;
}

.tokenSelectorTablet {
    width: 100%;
}

@media (max-width: 900px) {
    .customd .dropdownMenu {
        min-width: 23rem;
    }
}

@media (max-width: 796px) {
    .customd .dropdownMenu {
        min-width: 24rem;
    }
}

@media (max-width: 750px) {
    .customd .dropdownMenu {
        min-width: 24rem;
    }
}

@media (max-width: 600px) {
    .customd .dropdownMenu {
        min-width: 24rem;
    }
}

@media (max-width: 550px) {
    .customd .dropdownMenu {
        min-width: 12rem;
    }
}

@media (max-width: 992px) {
    .tokenSelector {
        min-width: 100% !important;
    }
}

@media (max-width: 796px) {
    .tokenSelector {
        min-width: 20rem
    }
}

@media (max-width: 750px) {
    .tokenSelector {
        min-width: 24rem
    }
}

@media (max-width: 600px) {
    .tokenSelector {
        min-width: 24rem
    }
}

@media (max-width: 550px) {
    .tokenSelector {
        min-width: 12rem;
        width: 100%
    }
}

.tokenInfo {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.tokenInfo span {
    margin-right: 10px;
    padding: 0px 10px;
    font-size: var(--fontSize14h);
    display: flex;
    align-items: center;
}

.tokenInfo .token-arrow {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.tokenInfo .tokenName {
    min-width: 50px;
    height: 16px;
    text-align: center;
    background: #2A3541;
    font-family: var(--fontFamilyFredokaOne);
    font-weight: 400;
    font-size: 10px;
    line-height: 14px;
    letter-spacing: 0em;
    border-radius: 16px;
    display: inline-flex;
    justify-content: space-around;
    align-items: center;
}

.customd .dropdownMenu {
    /* transform: translate(0rem, 27px) !important; */
    padding: 10px;
    cursor: pointer;
    background: #161f42;
    color: #fff;
    min-width: 400px;
    margin-top: 7px !important;
}

@media (max-width: 900px) {
    .customd .dropdownMenu {
        min-width: 23rem;
    }
}

@media (max-width: 796px) {
    .customd .dropdownMenu {
        min-width: 24rem;
    }
}

@media (max-width: 750px) {
    .customd .dropdownMenu {
        min-width: 24rem;
    }
}

@media (max-width: 600px) {
    .customd .dropdownMenu {
        min-width: 24rem;
    }
}

@media (max-width: 550px) {
    .customd .dropdownMenu {
        min-width: 12rem;
    }
}

.tokenSelectorModal {
    width: 280px;
}

@media (max-width: 720px) {
    .tokenSelectorModal {
        width: 100vw;
    }
}

.optionItems {
    padding-bottom: 12px;
    margin: 0;
}

.optionItems .option-item {
    display: flex !important;
    justify-content: space-between !important;
    padding: 8px 24px;
    cursor: pointer;
}

.optionItems .option-item.active {
    background: #ffffff10;
    color: var(--whiteColor);
}

.tokenSelectorModal .optionItems .option-item:hover {
    background: #ffffff05;
}

.tokenSelectorModal .optionItems .option-item .token-info span {
    margin-left: 6px;
}

.tokenSelectorModal .optionItems .option-item .token-balance-info {
    text-align: end;
}

.dropdownMenu {
    padding: 10px;
    cursor: pointer;
    background: #111827;
    color: #fff;
    width: 100%;
    margin-top: 12px !important;
    border: 1px solid #484F60;
}

@media screen and (max-width: 992px) {
    .tokenSelectorDiv {
        width: 100%;
        max-width: none !important;
    }

}

@media (max-width: 821px) {
    .dropdownMenu {
        min-width: 24rem;
    }

    .tokenSelector {
        max-width: 50.125rem !important;
    }

    .tokenSelectorDiv {
        max-width: 50.125rem !important;
    }
}

@media (max-width: 750px) {
    .dropdownMenu {
        min-width: 24rem;
    }
}

@media (max-width: 600px) {
    .dropdownMenu {
        min-width: 24rem;
    }
}

@media (max-width: 550px) {
    .dropdownMenu {
        min-width: 12rem;
    }
}

.div1 {
    max-width: 30rem;
    width: 100%;
}

.button1 {
    display: flex;
    justify-content: space-between;
    background: #030712;
    box-shadow: none;
    height: 40px;
    align-items: center;
    border-radius: 12px;
}

.div2 {
    padding: 4px;
}