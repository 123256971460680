.chat {
    min-width: 420px !important;
    max-height: 92vh;
    display: flex;
    flex-direction: column;
    height: 100%;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 999;
    background: #12151D;
    border-left: 1px solid rgba(209, 212, 219, 0.25);
    transition: width 2s;
    transition: left 1s linear;
}

.chat-window {
    width: 100%;
    max-width: 420px !important;
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 0;
    display: flex;
    flex: 1;
    flex-direction: column;
    height: -webkit-fill-available;
    position: fixed;
    right: 0;
    top: 56px;
    z-index: 999;
    background: #12151D;
    border-left: 1px solid rgba(209, 212, 219, 0.25);
    overflow-y: scroll;
    overscroll-behavior: contain;
    bottom: 5rem;
    transition: width 2s;
}

.chat-input {
    bottom: 0;
    position: fixed;
    right: 0;
    max-width: 100%;
    background: #12151D;
    z-index: 999;
    border-left: 1px solid rgba(255, 255, 255, 0.1);
    gap: 0.5rem;
    padding: 0 0.5rem;
}

.chat.sidebar {
    background-color: rgba(0, 0, 0, 0.1);
    border-left: #040524d1 1px solid;
}

.chat-input {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: white;
    background: #20242F;
    width: 420px;
    height: 80px;
}

.chat-input-field {
    flex: 1;
    outline: none;
    border: 1px solid transparent;
    background: #0C0E13;
    color: #FFFFFF;
    height: 48px;
    padding: 12px 35px 12px 16px;
    gap: 8px;
    border-radius: 8px;
    opacity: 1px;
    font-family: var(--fontFamilyNunito);
    font-size: 16px;
    font-weight: 400;
    max-width: 316px;
}

.chat-input-field:focus {
    border: 1px solid rgba(209, 212, 219, 0.25);
}

.cursor {
    opacity: 0.4;
    color: #fff;
}

.cursor:hover,
.cursor.active {
    opacity: 1;
}

.emoji-picker {
    position: absolute;
    bottom: 5rem;
    right: 2rem;
    background: #1f2937;
    border: none;
}

.chat-header {
    width: 420px;
    height: 56px;
    padding: 16px 24px 16px 24px;
    gap: 12px;
    opacity: 1px;
    position: relative;
    margin: 0 !important;
}

.icon-container {
    z-index: 99;
}

.title-container {
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    gap: 12px;
}


/* @media screen and (max-width: 376px) {
    .chat {
        min-width: 0 !important;
    }
}

@media screen and (max-width: 321px) {
    .chat {
        min-width: 0 !important;
    }

    .chat-header {
        width: 22rem !important;
    }
}

@media screen and (min-height: 1000px) {
    .chat {
        max-height: fit-content !important;
    }

    .chat-window {
        height: 90% !important;
        max-height: calc(96vh - 95px);
    }

} */

@media screen and (max-width: 376px) {
    .chat {
        min-width: 100% !important;

    }

    .chat-header {
        width: 100% !important;
    }

    .chat-window {
        overflow-x: hidden !important;
    }
}

.emojIcon {
    position: absolute;
    right: 15%;
    background: #12151d;
    align-items: center;
    display: flex;
}

@media screen and (max-width: 321px) {
    .chat-input-field {
        padding: 12px 20px 12px 12px;
    }

    .emojIcon {
        right: 3.3rem !important;
    }
}

.highlighted {
    border: 2px solid #007bff;
    /* Change the border color to highlight */
}

.span1 {
    font-size: 16px;
    font-weight: 600;
    color: #FFF;
}

.ruleUl {
    color: #FF7F7F;
    font-family: var(--fontFamilyNunito);
    font-weight: 500;
    line-height: 26px;
    font-size: clamp(12px, 3.5vw, 16px);
}