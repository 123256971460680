/* ============ Table ============ */
.table-main {
    margin: 0 auto;
    border-radius: 16px;
    border: 1px solid var(--borderColor);
    overflow: hidden;
}

.table-main .table-head {
    display: flex;
    background-color: var(--primaryColor);
    color: var(--whiteColor);
    padding: 16px;
    font-weight: bold;
}

.table-main .table-head-li {
    text-align: center;
}

.table-main .table-row {
    display: flex;
    padding: 16px;
    cursor: pointer;
    align-items: center;
    color: var(--whiteColor);
}

.table-main .table-row:nth-of-type(2n) {
    background: var(--secondaryBgColor);
}

.table-main .table-row:hover {
    background: var(--secondaryBgColor);
}

.table-main .table-row .table-row-li {
    text-align: center;
    flex: 0.4;
}

.table-main .table-row .table-row-li span {
    font-size: 12px;
    display: block;
    opacity: 0.5;
}

/* ============ Snackbar ============ */
.snackbar-root {
    top: 80px !important;
}

.snackbar .links {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 4px;

    &:hover {
        text-decoration: none;
    }
}

.snackbar .header {
    display: flex;
    align-items: center;
    width: 100%;
    /*height: 50px;*/
    height: 8px;
    padding: 0 12px 0 18px;
    justify-content: space-between;
    border-radius: 4px 4px 0 0;
}

.snackbar .body {
    /*background: white;*/
    backdrop-filter: blur(100px);
    border-radius: 0 0 4px 4px;
    padding: 12px;
    font-weight: 600;
    font-size: 14px;
    width: 100%;
}

.snackbar .content {
    background: rgba(255, 255, 255, 0.02);
    backdrop-filter: blur(70px);
    border-radius: 4px 4px 0 0;
    border: 1px solid;
    width: 378px;
    border-image-source: linear-gradient(180deg,
            rgba(255, 116, 38, 0.1) 0%,
            rgba(255, 255, 255, 0) 100%);
    margin-right: 15px;
    color: #ffffff;
    opacity: 0.88;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;

    @media (max-width: 600px) {
        width: 100%;
    }
}

/* ============ Opitons ============ */
.ul-items {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    /* flex-wrap: wrap; */

    @media (max-width: 600px) {
        overflow: scroll;
        overscroll-behavior: contain;
        max-width: 650px;
    }

}

.li-item {
    padding: 8px 24px;
    background: #ffffff10;
    color: #ffffff;
    border-radius: 6px;
    margin-right: 12px;
    border: 1px solid var(--borderColor);
    width: max-content;
    margin-bottom: 4px;
    cursor: pointer;
    transition: 0.2s ease-in-out;
}

.li-item.selected {
    box-shadow: 1px 1px 5px #ffffff30;
    color: var(--highlightFontColor);
    text-shadow: 1px 1px 10px var(--highlightFontColor);
    border: 1px solid var(--highlightFontColor);
}

/* buttons */
.singInButton {

    width: 119px;
    height: 40px;
    padding: 8px 24px 8px 24px;
    border-radius: 4px;
    gap: 10px;
    color: #fff;
    background: #FFBC40;
    box-shadow: 0px -33px 37.400001525878906px 0px #E98E07 inset;
    font-family: var(--fontFamilyNunito);
    font-size: 14px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;
    margin-right: 8px
}

.yellowButton {
    color: #fff;
    border-radius: 6px;
    border-style: solid;
    border-width: thin;
    font-size: 14px;
    font-weight: 700;
    /* font-family: "Geogrotesque Wide sans-serif"; */
    background: #ffe81a;
    box-shadow: 0px -33px 37.400001525878906px 0px #E98E07 inset;
}

.playButton {
    border-radius: 10px;
    width: 100%;
    padding: 8px;
    color: #FFF;
    font-family: var(--fontFamilyFredokaOne);
    font-size: clamp(10px, 2.2vh, 20px);
    font-weight: 400;
    letter-spacing: 0em;
    text-align: center;
    height: 100%;
    max-height: 48px;
    text-transform: uppercase;
    border: none;
    background: linear-gradient(180deg, #FBAE56 0%, #F28100 100%);
    box-shadow: 0px 0px 4.1px 0px #FFFFFF inset;
}

.inputButtonDice {
    width: 100%;
    color: #ffffffc7;
    font-weight: 400;
    border-color: transparent;
    font-family: var(--fontFamilyFredokaOne);
    font-size: 24px;
    outline: none;
    border: none;
    background: none;
}

.cancelButton {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
    transition: all 0.1s ease 0s;
    appearance: none;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    cursor: pointer;
    -webkit-user-select: none;
    user-select: none;
    height: 48px;
    padding: 0px 24px;
    border-radius: 8px;
    font-style: normal;
    font-weight: 800;
    font-size: 14px;
    color: #fff;
    width: 100%;
    margin: 24px 0px;
    background: linear-gradient(rgba(209, 209, 219, 0) 0%, rgb(16, 5, 37) 100%);
    border: 1px solid rgba(255, 255, 255, 0.168627451);
    --bs-btn-hover-bg: #ffffff00;
    --bs-btn-hover-color: #ffffff00;
    --bs-btn-hover-border-color: #ffffff45;
    --bs-btn-color: #ffffff00;
    --bs-btn-bg: #ffffff00;
    --bs-btn-border-color: #ffffff00;
    --bs-btn-active-bg: #ffffff00;
}

/* Token Selector */
.top-nav-account {
    display: flex;
    justify-content: flex-end;
}

/* Login Modal Image */
.imgDiv {
    background-image: url('/src/assets/loginRegister.png');
}

/* input error */
.error {
    /* color: rgba(255, 232, 26, 0.777); */
    color: red;
    font-size: 10px;
    padding: 0px 5px 0px 15px;
}

/* token selector */
.option-item {
    display: flex !important;
    justify-content: space-between !important;
    background: #100525;
    border-radius: 1rem;
    margin: 5px;
    padding: 5px;
}

/* balances */
.info-part {
    background: #1e1830;
    border-radius: 15px;
    padding: 1rem;
    margin: 1rem;
    /* max-width: 50%; */
}

.all-balances-data {
    background: #1e1830;
    border-radius: 15px;
    padding: 1rem;
    margin: 1rem;
    /* max-width: 50%; */
}

.buttonCenter {
    margin: 0;
    position: absolute;
    /* top: 50%; */
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.gameModalHeading {
    font-family: var(--fontFamilyFredokaOne);
    font-size: 32px;
    font-weight: 400;
    line-height: 38px;
    letter-spacing: 0em;
    text-align: center;
}

.stickyTop {
    position: fixed;
    top: 0;
    width: 100%;
}

input[type="password"] {
    -webkit-text-security: 'square';
}

.navlink {
    text-decoration: none
}

.ActiveNav {
    color: rgb(255, 255, 193) !important;
    text-shadow: rgb(255, 93, 0) 0px 0px 10px !important;
}

.InactiveNav {
    color: rgb(209, 201, 201);
}

[data-title]:hover:after {
    opacity: 1;
    transition: all 0.1s ease 0.5s;
    visibility: visible;
    top: -20px;
    width: 140px;
}

[data-title]:after {
    content: attr(data-title);
    color: #fff;
    position: absolute;
    white-space: wrap;
    word-break: keep-all;
    opacity: 1;
    border-radius: 2px;
    z-index: 99999;
    visibility: hidden;
    font-size: 11px;
}

[data-title] {
    position: relative;
}

.loader {
    width: 100%;
    height: 100%;
    border: 3px solid #FFF;
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
}

@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

/* input field */
input:focus {
    outline: none;
    border-color: #fff;
}