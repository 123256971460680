.fNav {
    z-index: 888;
    max-height: 66px;
    padding: 10px 0px;
    gap: 0px;
    border: 1px;
    background: #1C252E;
    border-top: 1px solid #58687C;
    align-items: center;
    bottom: 0;
    position: sticky !important;
    width: 100%;
}

.img {
    cursor: pointer;
}

.title {
    font-family: var(--fontFamilyInter);
    font-size: 11px;
    font-weight: 400;
    line-height: 12px;
    text-align: center;
    top: 30px;
    left: 34px;
    gap: 0px;
    cursor: pointer;
    text-decoration: none;
}

@media screen and (min-width: 992px) {
    .fNav {
        display: none;
    }
}