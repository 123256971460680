.custom-modal
  // width: 500px
  // background: var(--secondaryBgColor)
  // border-radius: 6px
  // box-shadow: 0 6px 21px rgba(0, 0, 0, 0.5)
  display: inline-block
  position: relative
  width: 100%
  max-width: 840px
  vertical-align: middle
  border-radius: 10px
  animation: 0.15s ease-out 0s 1 normal both running animation-1h5r8ub
  background: rgb(26, 29, 41)
  box-shadow: rgba(0, 0, 0, 0.3) 0px 10px 20px, rgba(255, 255, 255, 0.04) 0px 1px 0px inset, rgba(0, 0, 0, 0.25) 0px -4px 0px inset
  border-radius: 10px
  // min-height: 37rem

  @media (max-width: 600px)
    width: 100%
    //bottom: 0
    //position: absolute

  b
    color: var(--highlightFontColor)

  a
    color: var(--highlightFontColor)

    &:visited
      color: var(--highlightFontColor)
      text-decoration: none
      border-bottom: 2px dotted var(--highlightFontColor)

      color: var(--highlightFontColor)
      text-decoration: none
      border-bottom: 2px dotted var(--highlightFontColor)

  .link
    color: var(--highlightFontColor)
    text-decoration: none
    border-bottom: 2px dotted var(--highlightFontColor)

  .custom-modal-title
    padding: 16px 24px
    display: flex
    align-items: center
    justify-content: space-between

  .custom-modal-title-content
    flex: 1
    color: var(--highlightFontColor)
    text-align: center
    font-size: 18px
    font-weight: bold

  .custom-modal-content
    // padding: 0 16px 16px 16px
    background-color: #1a1d29
    border-radius: 10px
    // overflow-x: scroll
    // height: 100vh
    // todo fix scroll for modal
    transform: translateZ(10px)

  .close-icon
    font-size: 16px
    font-weight: bold

    /* color: var(--highlightFontColor);
    cursor: pointer

// .ReactModal__Content
//   @media (max-width: 420px)
//     height: max-content !important
//     inset: unset !important
//     margin-right: 0 !important
//     transform: unset !important
//     bottom: 0 !important
