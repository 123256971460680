.heading {
    font-family: var(--fontFamilyFredokaOne);
    font-size: 1.4285714285714286em;
    font-weight: 400;
    letter-spacing: 0.02em;
    text-align: left;
    text-transform: uppercase;
    margin: 0;
}

.sentence {
    font-family: var(--fontFamilyNunito);
    font-size: 1em;
    font-weight: 400;
    text-align: left;
    color: rgba(255, 255, 255, 1);
    margin: 0;
}

.container {
    background: linear-gradient(180deg, #414D5C 0%, #1B2025 100%);
    box-shadow: 0px 3px 2.5px 0px #5D6976 inset;
    border-radius: 24px;
    display: flex;
    flex-direction: column;
    /* Set flex-direction to column */
    position: relative;
    justify-content: flex-end;
    height: 321px;
    padding: 22px;
    gap: 0px;
    opacity: 1px;
    min-width: 100%;
}

.text {
    font-family: var(--fontFamilyNunito);
    font-size: 1em;
    font-weight: 400;
    line-height: 1.4285714285714286em;
    text-align: left;
    z-index: 2;
    position: relative;
    gap: 8px;
    display: flex;
    flex-direction: column;
}


.image {
    position: absolute;
    /* Position absolute for positioning inside parent div */
    top: 0;
    /* Align to top */
    right: 0;
    /* Align to right */
}

.img1 {
    float: right;
    mix-blend-mode: plus-lighter;
}

.div1 {
    display: flex;
    flex-direction: column;
    gap: 0.57em;
}

.div2 {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.b2p1 {
    font-size: 0.875em;
    font-family: var(--fontFamilyInter);
    font-weight: 500;
}

.img2 {
    position: relative;
    width: 1.25rem;
    height: 1.25rem;
    align-items: center;
    justify-content: center;
}