.heading {
    font-family: var(--fontFamilyFredokaOne);
    font-size: 24px;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: 0.02em;
    background-image: linear-gradient(to right, #FFD809 0%, #FF8A00 100%);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    text-align: center;
    word-wrap: break-word;
    white-space: pre-wrap;
    text-transform: uppercase;
    margin: 0;
}

.sentence {
    font-family: var(--fontFamilyNunito);
    font-size: 1em;
    font-weight: 400;
    line-height: 1.42em;
    text-align: center;
    color: rgba(255, 255, 255, 1);
    word-break: break-word;
    min-width: none;
    max-width: 11.875rem;
}

.container {
    background-image: url('../../../static/images/designStatic.svg'), linear-gradient(180deg, #414D5C 0%, #1B2025 100%);
    /* min-width: 354px; */
    height: 254px;
    /* padding: 30px 68.25px 26px 68.75px; */
    gap: 0px;
    border-radius: 24px;
    opacity: 1px;
    box-shadow: 0px 3px 2.5px 0px #5D6976 inset;
}


.text {
    font-family: var(--fontFamilyNunito);
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    margin-top: auto;
    /* Push the text to the bottom */
    z-index: 2;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 8px
}


.image {
    display: flex;
    align-items: center;
    justify-content: center;
}

.div1 {
    padding: 40px;
}

@media screen and (max-width: 770px) {
    .div1 {
        padding: 30px 5px 26px 5px;
    }
}