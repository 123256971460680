.gameCard {
    position: relative;
    width: 100%;
    max-width: 100%;
    height: 100%;
    gap: 0px;
    border-radius: 24px;
    overflow: hidden;
    /* Ensure the background-wrapper doesn't overflow */
}

.gameImg {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 100%;
}

.box {
    width: 555.25px;
    height: 287px;
    margin: 0 auto;
    padding: 2px;
    background-color: #eaab00;
    /* gold */
    background-image: url('data:image/gif;base64,R0lGODlhAQABAPAAAOqrAP///yH5BAAAAAAALAAAAAABAAEAAAICRAEAOw=='), url('data:image/gif;base64,R0lGODlhAQABAPAAAOqrAP///yH5BAAAAAAALAAAAAABAAEAAAICRAEAOw=='), url('data:image/gif;base64,R0lGODlhAQABAPAAAP///////yH5BAAAAAAALAAAAAABAAEAAAICRAEAOw==');
    background-repeat: no-repeat;
    background-size: 0 2px, 0 100%, 0% 2px;
    background-position: top center, top center, bottom center;
    -webkit-animation: drawBorderFromCenter 4s infinite;
}

.content {
    background: white;
    padding: 2em;
    text-align: center;
    text-transform: uppercase;
}

.game-name {
    position: absolute;
    bottom: 5%;
    left: 50%;
    transform: translateX(-50%);
    margin-bottom: 10px;
    text-transform: uppercase;
    text-align: center;
    font-family: var(--fontFamilyFredokaOne);
    font-weight: 400;
    font-size: 24px;
    -webkit-background-clip: text;
    background-clip: text;
    color: #FFA0A0;
    width: 100%;
}

.txtdice-2d {
    color: #FFE2A0;
}

.txtslot-2d {
    color: #A0C0FF;
}

.txtgame {
    color: #FFA0A0;
}


.background-wrapper-all {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: inherit;
    overflow: hidden;
    /* Ensure conic gradient doesn't overflow */
}

.background-wrapper-all::before {
    content: '';
    position: absolute;
    top: -50%;
    left: -50%;
    width: 200%;
    /* Make it wider to ensure full rotation */
    height: 210%;
    /* Center the pseudo-element horizontally */
    background: repeating-conic-gradient(#ffffff08 0deg 15deg, #fff0 0deg 30deg), linear-gradient(180.07deg, #892525 .06%, #370606 99.94%);
    box-shadow: 0px 3px 2.5px 0px #6983BF inset;
    animation: rotateBackground 20s linear infinite;
}

.background-wrapper-dice-2d {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: inherit;
    overflow: hidden;
    /* Ensure conic gradient doesn't overflow */
}

.background-wrapper-dice-2d::before {
    content: '' !important;
    position: absolute !important;
    top: -50% !important;
    left: -50% !important;
    width: 200% !important;
    /* Make it wider to ensure full rotation */
    height: 210% !important;
    /* Center the pseudo-element horizontally */
    box-shadow: 0px 3px 2.5px 0px #BFA569 inset !important;
    background: repeating-conic-gradient(hsl(0deg 0% 100% / 3%) 0deg 15deg, hsl(0deg 0% 100% / 0%) 0deg 30deg), linear-gradient(180.07deg, #896B25 0.06%, #372806 99.94%) !important;
    animation: rotateBackground 20s linear infinite !important;
}

.background-wrapper-slot-2d {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: inherit;
    overflow: hidden;
    /* Ensure conic gradient doesn't overflow */
}

.background-wrapper-slot-2d::before {
    content: '' !important;
    position: absolute !important;
    top: -50% !important;
    left: -50% !important;
    width: 200% !important;
    /* Make it wider to ensure full rotation */
    height: 210% !important;
    /* Center the pseudo-element horizontally */
    background: repeating-conic-gradient(hsl(0deg 0% 100% / 3%) 0deg 15deg, hsl(0deg 0% 100% / 0%) 0deg 30deg), linear-gradient(180.07deg, #255489 0.06%, #061D37 99.94%) !important;
    box-shadow: 0px 3px 2.5px 0px #6983BF inset !important;
    animation: rotateBackground 20s linear infinite !important;
}

@keyframes rotateBackground {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

.radial {
    box-shadow: inset 0 3px 2.5px 0 #bf6969;
}

.dice-2d {
    box-shadow: 0px 3px 2.5px 0px #BFA569 inset;
}

.slot-2d {
    box-shadow: 0px 3px 2.5px 0px #6983BF inset;
}