.card {
    width: 100%;
    background-color: none;
    border: 2px solid transparent;
    position: relative;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 26px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 18.75rem;
}

.mini-card {
    width: calc(100% - 2px);
    height: calc(100% - 2px);
    background-color: none;
    border-radius: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 9;
}

.shimmer {
    position: absolute;
    width: 4px;
    background-color: #FFD700;
    border-radius: 2px;
    animation: shimmer 8s infinite;
    opacity: 1;
    /* Initial opacity to ensure it's visible at the start */
}

.shimmer.left {
    animation: shimmer-left 8s infinite;
    opacity: 1;
    /* Initial opacity to ensure it's visible at the start */
}

@keyframes shimmer {
    0% {
        top: 100%;
        left: 50%;
        transform: translateX(-50%);
        height: 10px;
        width: 100px;
        opacity: 1;
    }

    15% {
        top: 100%;
        left: 75%;
        transform: translateX(-50%);
        height: 10px;
        width: 100px;
        opacity: 1;
    }

    25% {
        top: 100%;
        left: 100%;
        transform: translate(-50%, -50%);
        height: 200px;
        width: 50px;
        opacity: 0.8;
    }

    75% {
        top: 0;
        left: 100%;
        transform: translate(-50%, -50%);
        height: 350px;
        opacity: 0.5;
    }

    100% {
        top: 0;
        left: 100%;
        transform: translateX(-100%);
        height: 0;
        width: 0;
        opacity: 0;
    }
}

@keyframes shimmer-left {
    0% {
        top: 100%;
        left: 50%;
        transform: translateX(-50%);
        height: 10px;
        width: 100px;
        opacity: 1;
    }

    15% {
        top: 100%;
        left: 35%;
        transform: translateX(-50%);
        height: 10px;
        width: 100px;
        opacity: 1;
    }

    25% {
        top: 100%;
        left: 0%;
        transform: translate(-50%, -50%);
        height: 200px;
        width: 50px;
        opacity: 0.8;
    }

    75% {
        top: 0;
        left: 0%;
        transform: translate(-50%, -50%);
        height: 350px;
        opacity: 0.5;
    }

    100% {
        top: 0;
        left: 0%;
        transform: translateX(0%);
        height: 0;
        width: 0;
        opacity: 0;
    }
}