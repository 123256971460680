.div0 {
    display: flex;
    gap: 0.5rem;
}

.div1 {
    background: #D9D9D9;
    width: 36px;
    height: 36px;
    border-radius: 5rem;
}

.img1 {
    background: #D9D9D9;
    width: 36px;
    height: 36px;
    border-radius: 5rem;
}

.div2 {
    display: flex;
    flex-direction: column;
    font-size: 16px;
    gap: 0.1rem;
}

.span1 {
    padding-right: 0.9rem;
    cursor: pointer;
}