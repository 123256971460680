.leaderTabs {
    max-width: 1118.5px;
    /* height: 3rem; */
    /* height: 100%; */
    /* Set min-height instead of height */
    padding: 4px;
    gap: 8px;
    border-radius: 8px;
    opacity: 1px;
    background: #1C2939;
    margin: 0;
    display: flex;
    align-items: center;
}

/* @media screen and (max-width: 440px) {
    .leaderTabs {
        margin: 1%;
    }
} */

.walletTabs {
    max-width: 497px !important;
    height: 3rem;
    /* Set min-height instead of height */
    padding: 4px;
    gap: 8px;
    border-radius: 8px;
    opacity: 1px;
    background: #1C2939;
    margin: 0;
    display: flex;
}

@media screen and (max-width: 440px) {
    .tabs {
        height: 4rem !important;
    }

    .walletTabs {
        width: 100% !important;
        height: auto !important;
    }
}

.profileTabs {
    padding: 4px;
    gap: 8px;
    border-radius: 8px;
    background: #1C2939;
    display: flex;
    width: fit-content;
}