    .modalBackdrop {
        backdrop-filter: blur(4px);
        padding-bottom: 10rem;
    }

    .modalContent {
        background: #12151D;
        max-width: 545px;
        top: 2rem;
        gap: 0px;
        border-radius: 12px;
        border: 1px;
        border: 1px solid #47515D;
        height: auto;
    }

    .modalWallet {
        background: #111827 !important;
        width: 545px !important;
        height: 580px !important;
    }

    @media screen and (max-width: 1024px) {
        .modalContent {
            top: 0rem !important;
        }

    }

    @media screen and (max-width: 600px) {
        .modalContent {
            height: auto !important;
            width: auto !important;
            top: 3rem !important;
        }
    }

    @media screen and (min-width: 601px) {
        .modalWallet {
            transform: translate(-5%, 0%) !important;
        }

        .modalEditProfile {
            min-width: 525px;
        }
    }

    .modalBetDetail {
        width: 100%;
        height: 90% !important;
    }