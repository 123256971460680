@media screen and (min-width: 426px) {
    .gameBets {
        max-width: 60.125rem;
        margin: auto;
        width: 100%;
    }
}

@media screen and (max-width: 426px) {
    .gameBets {
        padding: 0 5vw;
    }
}

@media screen and (min-width: 426px) and (max-width: 1119px) {
    .gameBets {
        padding: 0 6.25vw;
    }
}

@media screen and (max-width: 991px) {
    .gameBets {
        margin: 0 !important;
    }
}

.div1 {
    display: flex;
    flex-direction: column;
    gap: 5rem;
}