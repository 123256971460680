.gameImg
  display: flex
  align-items: center
  justify-content: center
  position: relative

.game-name
  position: absolute
  bottom: 0
  left: 50%
  transform: translateX(-50%)
  margin-bottom: 10px
  text-transform: uppercase
  text-align: center
  font-family: var(--fontFamilyFredokaOne)
  font-weight: 400
  font-size: 24px
  background-image: linear-gradient(to right, #FFD809 0%, #FF8A00 100%)
  -webkit-background-clip: text
  background-clip: text
  color: transparent

  .gameSm
    padding: 2rem 2rem 2rem 2rem

.gamesSection
  display: flex
  justify-content: space-between
  flex-wrap: wrap
