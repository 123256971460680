.listItem {
    background: none;
    border: none;
    font-family: var(--fontFamilyNunito);
    font-size: "1em";
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;
    color: #fff;
    cursor: pointer;
    padding: .2rem;
    list-style-type: none;
}

.chatUl {
    background: #2f363e;
    position: absolute;
    bottom: 5rem;
    color: #fff;
    border-radius: 4px;
    width: 10rem;
    -webkit-appearance: none;
    appearance: none;
    text-align: center;
    padding: 0rem;
    margin: 0rem;
}