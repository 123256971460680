@media (max-width: 768px) {
    .container {
        flex-direction: column;
        /* Change to column layout */
    }
}


@media screen and (max-width: 767px) {
    .rowGap {
        gap: 1.5rem;
        display: flex;
        flex-direction: column;
    }
}

.gameImg {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    border: 1px solid #170c2b;
}

.game-name {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    margin-bottom: 10px;
    text-transform: uppercase;
    text-align: center;
    font-family: var(--fontFamilyFredokaOne);
    font-weight: 400;
    font-size: 24px;
    background-image: linear-gradient(to right, #FFD809 0%, #FF8A00 100%);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
}

.game-name .gameSm {
    padding: 2rem 2rem 2rem 2rem;
}

.gamesSection {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.gCard {
    padding-bottom: calc(var(--bs-gutter-x)* 1);
}

.home {
    margin-bottom: 60px;
    gap: 1.5rem;
    display: flex;
    flex-direction: column;
    max-width: -webkit-fill-available;
}

.div1 {
    align-items: center;
}

.div2 {
    gap: 1.5rem;
}