.open {
    display: block;
}

.close {
    display: none;
}

.profile {
    width: 520px;
    height: 100%;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 999;
    background: #12151D;
    border-left: 1px solid rgba(209, 212, 219, 0.25);
    color: #FFFFFF;
}

@media screen and (max-width: 521px) {
    .profile {
        min-width: 34% !important;
        width: 100% !important;
    }
}

.header {
    display: flex;
    align-items: center;
    /* padding: 2%; */
    height: 3rem;
    border-bottom: 1px solid rgba(55, 65, 81, 1)
}

.headerTxtGroup {
    gap: 1.5%;
}

.headerTxt {
    font-family: var(--fontFamilyNunito);
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    text-align: left;
    color: #FFFFFF;
    text-transform: uppercase;
}

.expanMenu {
    animation: show-dropdown 0.2s ease forwards;
    position: absolute;
    right: 6%;
    top: 2%;
    border: 1px solid #4B5563;
    background: #1F2937;
    width: fit-content;
    gap: 8px;
    border-radius: 8px;
    z-index: 9;
}

.expanMenu ul {
    list-style: none;
    font-family: var(--fontFamilyNunito);
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: #F9FAFB;
    padding: 0;
    margin: 0;
    width: 135px;
    align-items: center;
    text-align: center;
}

.expanMenu ul li {
    height: 36px;
    align-items: center;
    display: flex;
    justify-content: center;
}

.expanMenu ul li:not(:last-of-type) {
    border-bottom: 1px solid #4B5563
}

.username {
    font-family: var(--fontFamilyNunito);
    font-size: 18px;
    font-weight: 600;
    line-height: 28px;
    text-align: left;
    color: #FFFFFF;
}

.useremail {
    font-family: var(--fontFamilyNunito) !important;
    font-size: 16px !important;
    font-weight: 400 !important;
    line-height: 21.82px !important;
    text-align: left !important;
    color: #FFFFFF !important;

}

.email {
    display: flex;
    align-items: center;
    gap: 8%;
    justify-content: space-between;
    width: 166%;
}

@media screen and (max-width: 521px) {
    .email {
        width: 70vw;
    }
}

.editProfile {
    background: #1F2937;
    border: 1px solid #374151;
    max-width: 115px;
    max-height: 30px;
    padding: 4px 7px;
    gap: 4px;
    border-radius: 6px;
    /* border: 1px; */
    cursor: pointer;
}

.editProfileTxt {
    font-family: var(--fontFamilyNunito);
    font-size: 16px;
    font-size: clamp(12px, 4vw, 16px);
    font-weight: 400;
    line-height: 21.82px;
    text-align: left;
}

.card {
    /* max-width: 240px; */
    max-height: 82px;
    padding: 10px 16px 10px 16px;
    gap: 8px;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    background: #1F2937;
    border: 1px solid #374151;
    width: 48%
}

@media screen and (max-width: 375px) {
    .card {
        justify-content: space-between;
        max-height: fit-content;
    }
}

.referral {
    width: 50%;
}

.profileCardBox {
    gap: 1rem
}

@media screen and (max-width: 433px) {
    .profileCardBox {
        gap: .75rem
    }

}

.cardtitle {
    font-family: var(--fontFamilyNunito);
    /* font-size: 14px; */
    font-size: clamp(12px, 3vw, 14px);
    font-weight: 700;
    line-height: 24px;
    text-align: left;
    color: #FFFFFF;
    text-transform: uppercase;
    opacity: 70%;
}

.cardvalue {
    font-family: var(--fontFamilyNunito);
    /* font-size: 20px; */
    font-size: clamp(16px, 2.4vw, 20px);
    font-weight: 700;
    line-height: 30px;
    text-align: left;
    align-items: center;
    display: flex;
    overflow-x: scroll;
    overflow-y: hidden;
}

.viewAllBets {
    font-family: var(--fontFamilyFredoka);
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    text-align: right;
    background: linear-gradient(180deg, #FFD809 0%, #FF8A00 85.42%);
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    background-clip: text;
    text-transform: uppercase;
    cursor: pointer;
}

.cursorPointer {
    cursor: pointer;
}

.profileIcon {
    border-radius: 50%;
}

.tag {
    width: 109px;
    height: 26px;
    padding: 2px 8px 2px 8px;
    gap: 4px;
    border-radius: 6px;
    border: 1px;
    border: 1px solid rgba(137, 96, 48, 1);
    background: rgba(69, 47, 26, 1);
}

.infoDiv {
    gap: 0.1313rem;
    width: 100%;
}

.charm {
    margin: 0 2%;
}

.dots {
    display: inline-block;
    width: 8px;
    height: 8px;
    background-color: currentColor;
    border-radius: 50%;
    margin: 0 1px;
}

.emailContainer {
    display: inline-flex;
    align-items: center;
}

.emailText {
    display: flex;
    align-items: center;
}

@keyframes show-dropdown {
    0% {
        opacity: 0;
        transform-origin: perspective(200px) rotateX(-2deg);
        transition: visibility 1s, opacity 0.5s;
    }

    100% {
        opacity: 1;
    }
}

.profileContainer {
    height: 95%;
    overflow-y: scroll;
    overscroll-behavior: contain;
}

@media screen and (max-width: 521px) {
    .profileCard {
        display: flex;
        flex-direction: column;
    }

    .editProfileDiv2 {
        display: none !important;
        /* display: flex;
        justify-content: center !important;
        right: -2.5rem;
        position: relative; */
    }

    .editProfileDiv {
        /* display: flex;
        justify-content: center !important;
        right: -2.5rem;
        position: relative; */
        padding: 0;
        margin-top: 4%;
    }

    .profileCard {
        gap: 1rem;
    }

    /* .profileInfoCard {
        align-items: end !important;
    } */

    .profileIcon {
        top: 1.2rem;
        position: relative;
    }
}

@media screen and (min-width: 521px) {
    .profileCard {
        height: 82px;
    }

    .profileInfoCard {
        height: 100%;
    }

    .editProfileDiv3 {
        display: none !important;
    }
}

.headerSpan1 {
    position: absolute;
    top: 1.5%;
}

.charmSvg1 {
    position: absolute;
    right: 0;
}

.InfoCardiv1 {
    gap: 3%;
    display: flex;
    align-items: center;
}

.UbfiCard {
    opacity: 50%;
    cursor: pointer;
}

.InfoCardDiv2 {
    width: fit-content;
}

.infoCardTrans {
    text-transform: uppercase;
}