.refHeader {
    font-family: var(--fontFamilyFredokaOne);
    font-size: 28px;
    font-size: clamp(12px, 5vw, 28px);
    font-weight: 400;
    line-height: 32.04px;
    text-align: center;
    color: #FFFFFF;
    text-transform: uppercase;
}

.refDescription {
    font-family: var(--fontFamilyNunito);
    font-size: 16px;
    font-size: clamp(10px, 3vw, 16px);
    font-weight: 500;
    line-height: 24px;
    text-align: center;
    color: #FFFFFF;
}

.refLink {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.refLinkHeader {
    font-family: var(--fontFamilyNunito);
    font-size: 16px;
    font-size: clamp(10px, 3vw, 16px);
    font-weight: 600;
    line-height: 24px;
    text-align: left;
    color: #9CA3AF;
    float: left;
}

.refLinkBox {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 8px;
}

.refButton {
    display: flex;
    align-items: center;
    gap: 8px;

}

@media screen and (max-width: 500px) {
    .refLinkBox {
        flex-direction: column;
        width: 100%;
    }

    .refButton {
        width: 100%;
    }
}

.refShare {
    font-family: var(--fontFamilyFredoka);
    font-size: 16px;
    font-size: clamp(10px, 3vw, 16px);
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.02em;
    text-align: center;
    color: #FFFFFF;
}

.refContentBox2 {
    display: flex;
    gap: 0.484rem;
}

@media screen and (max-width: 500px) {
    .refContentBox2 {
        justify-content: center;
    }

}

.refCards {
    display: flex;
    width: 100%;
    /* padding: 0 1rem 0 0; */
    gap: 16px;
}

.refNoDataBox {
    background: #1F2937;
    width: 100%;
    height: 100%;
    max-width: 720px;
    min-height: 247px;
    border-radius: 16px;
    border: 1px solid #374151;
    display: flex;
    margin: auto;
}

.refNoDatapara {
    font-family: var(--fontFamilyNunito);
    /* font-size: 16px; */
    font-size: clamp(10px, 3vw, 16px);
    font-weight: 500;
    line-height: 24px;
    text-align: center;
    color: #F9FAFB;
    opacity: 60%;
}

.refNoDatah1 {
    font-family: var(--fontFamilyNunito);
    font-size: 20px;
    font-size: clamp(10px, 4vw, 20px);
    font-weight: 700;
    line-height: 28px;
    text-align: center;
    color: #F9FAFB;
}


.refNoDataCenter {
    margin: 0;
    position: relative;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    justify-content: center;
}