.contentBody {
    top: 120px;
    margin-bottom: 120px;
    position: relative;
    /* max-width: 1118.5px; */
    gap: 3rem;
    /* display: flex; */
    padding: 0 6rem;
    display: flex;
    justify-content: center;
    transition: margin-left 0.3s ease-in-out;
}

/* added margin for sidemenu expansion*/
.contentBody.sidebar-open {
    margin-left: 10rem;
}

@media screen and (max-width: 992px) {
    .contentBody {
        padding: 0 1rem;
    }
}

@media screen and (max-width: 426px) {
    .contentBody {
        top: 120px;
        position: relative;
    }
}

.authBody {
    justify-content: center;
    display: flex;
}