.profileImgDiv {
    border: 1px solid rgba(108, 118, 131, 1);
    border-radius: 50%;
    width: 80px;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    margin: auto;
}

.pencilDivImg {
    position: absolute;
    right: 8%;
    top: 3.3rem;
    cursor: pointer;
}

.pencilCircle {
    background: rgba(61, 69, 79, 1);
    border: 1px solid rgba(108, 118, 131, 1);
    border-radius: 50%;
    width: 28px;
    height: 28px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.profileImg {
    position: relative;
    margin: 0 5% 4%;
    cursor: pointer;
}

.usernameDivImg {
    right: 0px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: clamp(8px, 2.8vh, 20px);
    height: clamp(8px, 2.8vh, 20px);
    margin: 0 3% 0 0;
    cursor: pointer;
}

.inputDiv {
    position: relative;
}

.usernameInput {
    max-width: 100% !important;
    padding: 3% 3% 3% 7.5% !important;
}

.usernameDiv {
    align-items: center;
    justify-content: center;
    display: flex;
}

.username {
    font-family: var(--fontFamilyNunito);
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    text-align: center;
    padding: 10%;
}

.editName {
    background: rgba(3, 7, 18, 1) !important;
}


.usernameLabel {
    color: rgba(156, 163, 175, 1);
    font-family: var(--fontFamilyNunito);
    font-size: 16px;
    font-size: clamp(10px, 1.5vw, 16px);
    font-weight: 600;
    line-height: 24px;
    float: left;
    text-transform: capitalize;
}

.usernameContainer {
    width: 100%;
}

.advDiv {
    width: fit-content;
}

.errorSpan {
    color: #ff9494
}