.buttonPrime {
    text-transform: uppercase;
    cursor: pointer;
    color: #fff;
    /* height: 40px; */
    padding: 0px 16px 0px 16px;
    opacity: 1px;
    /* font-size: 16px; */
    font-size: clamp(12px, 3vw, 16px);
    font-weight: 500;
    margin: 0 0 0 1rem;
}

.none {
    background: transparent;
    border: none;
    margin: 0;
    padding: 0;
    align-self: center;
    display: inline-flex;
    text-transform: none;
    font-size: inherit;
}

.wallet {
    background: linear-gradient(180deg, #FBAE56 0%, #F28100 100%);
    box-shadow: 0px 0px 4.1px 0px #FFFFFF inset;
    width: 108px;
    border-radius: 12px;
    border-style: solid;
    border-width: thin;
    border-color: #2b2e46;
    text-shadow: 0px 1px #00000026;
    margin: 0;
}

.walletMobile {
    width: 100% !important;
    padding: 0;
    margin: 0;
}

.signIn {
    background: linear-gradient(180deg, rgba(217, 230, 255, 0.25) 0%, rgba(212, 226, 255, 0.0775) 100%);
    height: 40px;
    width: 88px;
    gap: 10px;
    border-radius: 12px;
    text-shadow: 0px 1px #00000026;
    border: 1px solid #747A89;
    margin: 0;
    padding: 0;
    font-size: 1em;
}

.singUp {
    background: linear-gradient(180deg, #FBAE56 0%, #F28100 100%);
    box-shadow: 0px 0px 4.1px 0px #FFFFFF inset;
    width: 108px;
    height: 40px;
    gap: 8px;
    border: 1px solid #747A89;
    border-radius: 12px;
    padding: 0;
    margin: 0;
}

.checkNow {
    min-width: 152px;
    height: 40px;
    padding: 8px 16px 8px 16px;
    border-radius: 12px;
    opacity: 1px;
    background: linear-gradient(180deg, #7C88A6 0%, rgba(108, 119, 145, 0.33) 100%);
    border-image-source: linear-gradient(118.5deg, rgba(255, 255, 255, 0.3) -5.98%, rgba(255, 255, 255, 0) 139.12%);
    box-shadow: 0px 4px 14px 0px #00000026;
    border: none;
    font-family: var(--fontFamilyNunito);
    margin: 0;
    font-size: 1em;
    font-weight: 600;
}

.seeDoc {
    border: none;
    background: transparent;
    margin: 0;
    padding: 0;
    text-transform: capitalize;
}

.activeTab {
    width: -webkit-fill-available;
    max-width: 364.83px;
    height: 100%;
    padding: 8px;
    gap: 8px;
    border-radius: 12px;
    opacity: 1px;
    background: linear-gradient(180deg, #415266 0%, #303A52 100%);
    border: none;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: var(--fontFamilyNunito);
    font-weight: 600;
    font-size: clamp(12px, 4vw, 14px);
}

.inactiveTab {
    width: -webkit-fill-available;
    max-width: 364.83px;
    /* min-height: 40px; */
    height: 100%;
    padding: 8px;
    gap: 8px;
    border-radius: 8px;
    opacity: 1px;
    background: #1C2939;
    border: none;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: var(--fontFamilyNunito);
    font-weight: 600;
    font-size: clamp(12px, 4vw, 14px);
}

@media screen and (max-width: 426px) {
    .inactiveTab {
        height: 60px;
    }

    .activeTab {
        height: 60px;
    }
}

@media screen and (max-width: 390px) {
    .inactiveTab {
        height: 40px;
    }

    .activeTab {
        height: 40px;
    }
}

.discord {
    /* width: 380px; */
    width: 100%;
    height: 44px;
    gap: 8px;
    border-radius: 12px;
    background: linear-gradient(180deg, #97A0FF 0%, #4D549E 100%);
    border: none;
    font-family: var(--fontFamilyNunito);
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    text-align: center;
    text-transform: none !important;
    padding: 0 !important;
    margin: 0 !important
}

.google {
    width: 184px;
    height: 44px;
    gap: 8px;
    border-radius: 12px;
    background: linear-gradient(180deg, #A0B4C9 0%, #576675 81.5%);
    border: none;
    font-family: var(--fontFamilyNunito);
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    text-align: center;
    text-transform: none !important;
    padding: 0 !important;
    margin: 0 !important
}

.twitter {
    width: 184px;
    height: 44px;
    gap: 8px;
    border-radius: 12px;
    background: linear-gradient(180deg, #A0B4C9 0%, #576675 81.5%);
    border: none;
    font-family: var(--fontFamilyNunito);
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    text-align: center;
    text-transform: none !important;
    padding: 0 !important;
    margin: 0 !important
}

.authContinue {
    max-width: 380px;
    max-height: 40px;
    width: 100%;
    padding: 2% 4%;
    gap: 8px;
    border-radius: 12px;
    background: linear-gradient(180deg, #FBAE56 0%, #F28100 100%);
    box-shadow: 0px 0px 4.1px 0px #FFFFFF inset;
    margin: 0 !important;
    border: none;
}

.profileButton {
    border: none;
    background: none;
    margin: 0;
    padding: 0;
}

.activate {
    width: 106px;
    height: 40px;
    gap: 10px;
    border-radius: 12px;
    border: 1px;
    border: 1px solid #747A89;
    background: linear-gradient(180deg, rgba(217, 230, 255, 0.25) 0%, rgba(212, 226, 255, 0.0775) 100%);
    padding: 0;
    margin: 0;
    cursor: pointer;
}

.understood {
    width: 140px;
    height: 40px;
    gap: 10px;
    border-radius: 12px;
    border: 1px solid #747A89;
    background: linear-gradient(180deg, rgba(217, 230, 255, 0.25) 0%, rgba(212, 226, 255, 0.0775) 100%);
    padding: 0;
    margin: 0;
    cursor: pointer;
}

.crossButton {
    margin: 0;
    padding: 0;
    background: none;
    border: none;
}

.inputSliderBox {
    background: #293B65;
    max-width: 36px;
    max-height: 36px;
    padding: 3%;
    gap: 8px;
    border-radius: 8px;
    margin: 0;
    border: none;
    width: 100%;
    align-items: center;
    display: flex;
    justify-content: center;
}

.headingGradient-btnblue {
    background: linear-gradient(180deg, #5B82AE 0%, #224268 100%);
    box-shadow: 0px 0px 5.5px 0px #FFFFFF4D inset;
    width: 100%;
    max-height: 68px;
    height: 100%;
    gap: var(--spacing0);
    border-radius: 8px;
    border: none;
    padding: 0 !important;
    margin: 0 !important;
    aspect-ratio: 224/68;
}

.headingGradient-btnnone {
    width: 100%;
    max-height: 68px;
    height: 100%;
    gap: var(--spacing0);
    background: none;
    border: none;
    padding: 0 !important;
    margin: 0 !important;
    aspect-ratio: 224/68;
}

.diceGameInc {
    border-right: 2px solid #1C334E;
    width: 48px;
    height: 100%;
    gap: 8px;
    padding: 0;
    margin: 0;
    background: #061B33;
    border: transparent;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    border-left: 2px solid #374151;
}

.diceGameDec {
    width: 48px;
    gap: 8px;
    padding: 0;
    margin: 0;
    background: #061B33;
    border: none;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    border-right: 2px solid #374151;
}

@media screen and (max-height: 769px) {
    .inputSliderBox {
        align-items: center;
        display: flex;
        justify-content: center;
        height: 26px;
        width: 26px;
        padding: 3%;
    }

    .inputSliderBox img {
        padding: .1rem;
        align-items: center;
        display: flex;
        justify-content: center;
    }

}

.betDetailsPlayNow {
    /* width: 117px; */
    width: clamp(100px, 20vw, 117px);
    /* height: 40px; */
    height: clamp(30px, 5.5vh, 40px);
    gap: 8px;
    border-radius: 12px;
    background: linear-gradient(180deg, #FBAE56 0%, #F28100 100%);
    box-shadow: 0px 0px 4.1px 0px #FFFFFF inset;
    font-family: var(--fontFamilyFredoka);
    font-size: clamp(14px, 2vw, 16px);
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.02em;
    text-align: center;
    padding: 0;
    margin: 0;
    border: transparent;
}

.gamePlay {
    height: clamp(30px, 5.5vh, 40px)
}

.startPlaying {
    background: linear-gradient(180deg, #FBAE56 0%, #F28100 100%);
    box-shadow: 0px 0px 4.1px 0px #FFFFFF inset;
    min-width: 172px;
    min-height: 40px;
    gap: 8px;
    border-radius: 8px;
    padding: 0;
    margin: 0;
    border: transparent;
}


/* Referral */
.refShare {
    background: linear-gradient(180deg, #A0B4C9 0%, #576675 81.5%);
    max-width: 116px;
    max-height: 44px;
    padding: 10px 16px 10px 16px;
    border-radius: 12px;
    border: none;
    box-shadow: 0px 1px 0px 0px #00000026;
    margin: 0;
    width: 100%;
}

.refCopy {
    background: linear-gradient(180deg, #FBAE56 0%, #F28100 100%);
    max-width: 97px;
    max-height: 44px;
    padding: 10px 12px 10px 12px;
    gap: 8px;
    border-radius: 12px;
    margin: 0;
    border: none;
    width: 100%;
}

@media screen and (max-width: 501px) {
    .refCopy {
        max-width: 50%;
        width: 50%;
    }

    .refShare {
        max-width: 50%;
        width: 50%;
    }
}

.walletNavBar {
    width: 50% !important;
    height: 100%;
}

.signUpSideNav {
    width: 100%;
}

.gameModalBtn1 {
    height: 40px;
    width: 172px;
}