.optionItem {
    display: flex;
    justify-content: space-between;
    /* background: #100525; */
    margin: 5px;
    padding: 10px;
    border-radius: 5px;
    font-size: 0.875em;
    font-family: var(--fontFamily);
}

.active {
    background-color: #374151;
    position: relative;
    padding: 10px;
    border-radius: 10px;
}

.active::before {
    content: '';
    position: absolute;
    top: -1px;
    left: -1px;
    right: -1px;
    bottom: -1px;
    border-radius: 10px;
    z-index: -1;
    border: 1px solid transparent;
    background: linear-gradient(180deg, #FBAE56 0%, #F28100 100%);
}

.tokenBox {
    background: #4C525F;
    min-width: 48px;
    height: 22px;
    padding: 2px 6px 2px 6px;
    gap: 8px;
    border-radius: 0.25rem;
    font-size: 0.75em;
    font-weight: 600;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}

.tokenInfo {
    gap: 0.25rem
}