@font-face {
  font-family: 'Fredoka';
  src: url('/src/assets/fonts/Fredoka.ttf') format('truetype');
}

@font-face {
  font-family: 'Fredoka One';
  src: url('/src/assets/fonts/FredokaOne-Regular.ttf') format('truetype');
}


@font-face {
  font-family: 'Nunito';
  src: url('/src/assets/fonts/Nunito.ttf') format('truetype');
}


@font-face {
  font-family: 'inter';
  src: url('/src/assets/fonts/Inter.ttf') format('truetype');
}

body {
  padding: 0 !important;
  margin: 0;
  font-family: var(--fontFamily) !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  overflow-x: hidden !important;
  background-color: var(--primaryBgColor);
  color: var(--fontColorWhite);
  font-size: var(--fontSize16h);
  width: 100%;
}


:root {
  --primaryBgColor: #111827;
  --secondaryBgColor: #141624;
  --primaryThemeColor: #221050;
  --secondaryThemeColor: #0a0e15;
  --primaryColor: #6457ff;
  --primaryFontColor: #a1a2a7;
  --secondaryFontColor: #7165ec;
  --highlightFontColor: #fdca39;
  --whiteColor: #e4e5e8;
  --secondaryBtnColor: #1f2432;
  --borderColor: rgba(255, 255, 255, 0.1);

  --fontColorWhite: #ffffff;
  --fontFamily: 'Fredoka';
  --fontFamilyNunito: 'Nunito';
  --fontFamilyFredokaOne: 'Fredoka One';
  --fontFamilyInter: 'Inter';

  /* w.r.t height */
  --fontSize14h: clamp(8px, 2vh, 14px);
  --fontSize16h: clamp(10px, 2vh, 16px);
  --fontSize18h: clamp(10px, 2vh, 18px);
  --fontSize20h: clamp(10px, 2.5vh, 20px);
  /* w.r.t width */
  --fontSize16w: clamp(14px, 2vw, 16px);
  --fontSize18w: clamp(12px, 1.5vw, 18px);
}

* {
  margin: 0;
  padding: 0;
}

p {
  margin: 0;
  padding: 0;
}

.link {
  color: var(--highlightFontColor);
  text-decoration: underline;
  cursor: pointer;
}

/* .cursor {
  cursor: pointer;
} */

h2,
h3 {
  color: var(--highlightFontColor);
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 16px;
  text-transform: uppercase;
}

h3 {
  font-size: clamp(10px, 2vh, 16px);
  color: #fff;
}



.digitalricemodal {
  margin: 1rem;
  padding: 2rem 2rem;
  display: flex;
  align-items: stretch;
  justify-content: space-around;
  align-content: center;
  position: absolute;
  top: 25%;
  right: 30%;
  background: #100525;
  border-radius: 2rem;
  flex-wrap: wrap;
  flex-direction: column;
  margin-top: 4rem;

  @media (max-width: 2680px) {
    margin-top: 10rem;
    right: 40%;
  }


  @media (max-width: 2000px) {
    margin-top: 10rem;
    right: 35%;
  }

  @media (max-width: 1600px) {
    margin-top: 10rem;
    right: 25%;
  }

  @media (max-width: 1118px) {
    margin-top: 10rem;

    right: 25%;
  }

  @media (max-width: 1000px) {
    margin-top: 10rem;

    right: 20%;
  }

  @media (max-width: 900px) {
    margin-top: 10rem;

    right: 15%;
  }

  @media (max-width: 800px) {
    margin-top: 10rem;

    right: 12%;
  }

  @media (max-width: 700px) {
    margin-top: 10rem;

    right: 5%;
  }

  @media (max-width: 640px) {
    margin-top: 10rem;

    right: 2%;
  }
}

/* Scrollbar Styles for Chrome, Safari, and Opera */
*::-webkit-scrollbar {
  background-color: #1F2937;
  width: 5px;
  height: 5px;
}

*::-webkit-scrollbar-thumb {
  background-color: #4B5563;
  border-radius: 2rem;
}

/* Hide scrollbar by default */
.hidden-scrollbar {
  scrollbar-width: none;
  /* Firefox */
  -ms-overflow-style: none;
  /* IE and Edge */
}

.hidden-scrollbar::-webkit-scrollbar {
  display: none;
  /* Chrome, Safari, Opera */
}



/* Emoji Picker React */

.epr_-6npj90 {
  width: 87% !important;
}

@media screen and (max-width: 426px) {
  .epr_-6npj90 {
    width: 87% !important;
  }
}

@media screen and (max-width: 400px) {
  .epr_-6npj90 {
    width: 87% !important;
  }
}


@media screen and (max-width: 396px) {
  .epr_-6npj90 {
    width: 84% !important;
  }
}

@media screen and (max-width: 391px) {
  .epr_-6npj90 {
    width: 85% !important;
  }
}

@media screen and (max-width: 390px) {
  .epr_-6npj90 {
    width: 84% !important;
  }
}

.epr_-xuzz9z {
  background: none !important;
}

.epr_-6npj90 {
  position: absolute !important;
  bottom: 5rem !important;
  right: 2rem !important;
  background: #1f2937 !important;
  border: none !important;
}

/* modal close button */
.btn-close {
  --bs-btn-close-bg: none;
  --bs-btn-close-color: #fffafa !important;
  display: flex;
  align-items: center;
}

@keyframes show-dropdown {
  0% {
    opacity: 0;
    transform-origin: perspective(200px) rotateX(-2deg);
    transition: visibility 0.45s, opacity 0.5s;
  }

  100% {
    opacity: 1;
  }
}

/* side menu */
.offcanvasC.show:not(.hiding) {
  transform: translateX(-0%) !important;
  border-style: solid;
  border-color: #D1D4DB33;
  background: #1f2937 !important;
  height: 100%;
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: 3;
  border-left: 0;
  border-width: thin;
}

.offcanvasC.offcanvas-startC {
  transform: translateX(-57.5%) !important;
  height: 100%;
  position: fixed;
  border-left: 0;
}

.offcanvasC {
  visibility: visible !important;
  transition: transform 0.1s ease-in-out;
  height: 100%;
  position: fixed;
  z-index: 3;
  border-width: thin;
}

.offcanvas {
  position: fixed;
  bottom: 0;
  z-index: var(--bs-offcanvas-zindex);
  display: flex;
  flex-direction: column;
  max-width: 100%;
  color: var(--bs-offcanvas-color);
  visibility: hidden;
  background-color: transparent !important;
  background-clip: padding-box;
  outline: 0;
  transition: var(--bs-offcanvas-transition);
}

/* Tooltip */
.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
  opacity: 100%;
  cursor: pointer;
  z-index: 9;
}

.tooltip .tooltiptext {
  visibility: hidden;
  background-color: #1F2937;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 10px;
  position: absolute;
  z-index: 1;
  bottom: 0%;
  left: 400%;
  margin-left: -60px;
  opacity: 0;
  transition: opacity 0.3s;
  font-family: var(--fontFamilyNunito);
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  width: max-content;
  min-height: 32px;
  align-items: center;
  display: flex;
}

.tooltip .tooltiptext::before {
  content: "";
  position: absolute;
  top: 50%;
  left: -20px;
  /* Adjust to position the triangle correctly */
  transform: translateY(-50%);
  border-width: 12px;
  border-style: solid;
  border-color: transparent #1F2937 transparent transparent;
  /* Adjusted to match the background color */
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

.tooltipTextRef {
  left: 600% !important;
  top: 0% !important;
}

.tooltipProfileEmail {
  left: 40% !important;
  top: 100% !important;
  cursor: pointer;
  font-size: clamp(8px, 1.7vh, 14px) !important;
}